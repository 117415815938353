export default {
  actions: {
    async preloadAppData({ rootGetters, dispatch: nativeDispatch }) {
      function fakeDispatch(target) {
        return new Promise((resolve) => {
          setTimeout(() => {
            nativeDispatch(target).then(resolve);
          }, 5000);
        });
      }

      // use switchable dispatch method
      const dispatch =
        process.env.VUE_APP_STORE_DEBUG_MODE === "1"
          ? fakeDispatch
          : nativeDispatch;

      // get these right away, even in debug mode
      nativeDispatch("getProfile");
      const orgs = nativeDispatch("getOrgs");
      // must have org info to continue
      await orgs;
      if (rootGetters.getCurrentOrg) {
        nativeDispatch("getSharedOrgs");
        dispatch("getTasks");
        dispatch("getRecords");
        dispatch("getNotifications");
        dispatch("getThreads").then(() => {
          dispatch("getMessages");
        });
        dispatch("getUsers");
        dispatch("getSettings");
        dispatch("getScenarios");
        dispatch("getSharedScenarios");
        if (rootGetters.getUserData?.admin) {
          dispatch("getExternalOrgs");
          dispatch("getAffiliateOrgs");
        }

        let { revenue_flag, purchases_flag, feature_flags } =
          rootGetters.getCurrentOrg;

        // LOAD GROWER PORTAL
        if (!revenue_flag && !purchases_flag) {
          dispatch("getVendors");
          dispatch("getCustomers");
          dispatch("getCrops");
          dispatch("getCropHistory");
          dispatch("getFields");
          dispatch("getGrowerLocations");
          dispatch("getEquipment");
          dispatch("getIntegrations");
          dispatch("getBuyerListings"); // vendor listings
          dispatch("getGrowerAllocations");
          // dispatch("getGrowerAllocationRecords");
          dispatch("getGrowerDeliveries");
          dispatch("getGrowerListings"); // grower produce listings
          dispatch("getGrowerCropOrders");
          dispatch("getCompanies");
          dispatch("getSeedCompanies");
          // dispatch("getGrowerAgreements");
          dispatch("getGrowerProducts");
          dispatch("getGrowerQuotes");
          dispatch("getGrowerOrders");
          // dispatch("getGrowerServices");
          dispatch("getGrowerRecommendations");
          // dispatch("getGrowerOpportunities");
          dispatch("getGrowerMarketOffers");
          dispatch("getGrowerCropCustomers");
          dispatch("getGrowerInputAgreements");

          // TODO: consolidate fetches into single payload
          dispatch("getGrowerInputListings");
          dispatch("getGrowerInputOrders");
        }

        // LOAD VENDOR PORTAL
        else if (revenue_flag) {
          dispatch("getOrgProfile");
          dispatch("getCustomers").then(() => {
            dispatch("getSharedFields");
            dispatch("getSharedTasks");
            dispatch("getSharedRecords");
            dispatch("getSharedEquipment");
          });
          dispatch("getIntegrations");
          dispatch("getGrowerListings");
          dispatch("getVendorTerritories");
          // dispatch("getVendorAgreements");
          dispatch("getVendorProducts");
          dispatch("getVendorQuotes");
          dispatch("getVendorOrders");
          // dispatch("getVendorServices");
          dispatch("getVendorRecommendations");
          dispatch("getVendorOpportunities");
          dispatch("getVendorInputAgreements");
          dispatch("getVendorInputListings");
          dispatch("getAllVendorInputUnits");

          // TODO: consolidate fetches into single payload
          dispatch("getVendorInputOrders");
        }

        // LOAD BUYER PORTAL
        else if (purchases_flag) {
          // await dispatch("getVendors");
          dispatch("getBuyerCropOrders");
          dispatch("getBuyerAllocations");
          dispatch("getBuyerAllocationRecords");
          dispatch("getBuyerDeliveries");
          dispatch("getBuyerListings");
          // dispatch("getBuyerRequests");
          dispatch("getBuyerMarketOffers");
          dispatch("getBuyerCropVendors");
          dispatch("getBuyerContractOriginators");
          dispatch("getBuyerDeliveries");
          //dispatch("getVendorTerritories");
          dispatch("getMemberOrgs");

          // LOAD CONTROLLER PORTAL
          if (feature_flags.includes("bp-controller")) {
            // await dispatch("getVendors");
            dispatch("getControllerCropOrders");
            dispatch("getControllerAllocations");
            dispatch("getControllerAllocationRecords");
            dispatch("getControllerMarketOffers");
            dispatch("getContracts");
            dispatch("getContractPremiums");
            dispatch("getControllerMarketAffiliates");
            dispatch("getControllerDeliveries");
            dispatch("getControllerContractSummaries");
            dispatch("getControllerOfferSummaries");
            //dispatch("getVendorTerritories");
          }
        }
      }
    },
  },
};

<template>
  <display-only
    displayTitle="Allocated Crops"
    :displayValue="cropTypesOfOrderAllocations"
  />
  <display-only
    displayTitle="Allocated Varieties"
    :displayValue="varietiesOfOrderAllocations"
  />
  <display-only
    displayTitle="Total Allocated Acres"
    :displayValue="sumAllocationAcresByOrderId?.toFixed(2)"
  />
  <display-only
    :displayTitle="cropAllocationUnitsLabel"
    :displayValue="estimatedAllocatedQty"
  />
</template>

<script>
import DisplayOnly from "@/components/form/DisplayOnly.vue";
import { tonsToBushels } from "@/components/composables/convertUnits";
export default {
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  components: {
    DisplayOnly,
  },
  computed: {
    allocations() {
      return this.$store.getters.getGrowerAllocationsByOrderId(this.orderId);
    },
    // crops() {
    //   // contains crop types, varieties, tpa
    //   return this.$store.getters.getExpandedCrops({});
    // },
    cropAllocationUnitsLabel() {
      let label = "Estimated Allocated Tons";
      let units = this.$store.getters.getGrowerCropOrderById(
        this.orderId,
      )?.units;
      // console.log("units: ", units, typeof units);
      if (
        units &&
        (units.toLowerCase() == "bushels" || units.toLowerCase() == "bu")
      ) {
        label = "Estimated Allocated Bushels";
      }
      return label;
    },
    cropTypesOfOrderAllocations() {
      //console.log("crop types");
      let cropTypes = [];
      for (let allocation of this.allocations) {
        let crop = this.$store.getters.getAnyCropById(allocation.crop);
        if (crop && !cropTypes.includes(crop.crop_type)) {
          cropTypes.push(crop.crop_type);
        }
      }
      return cropTypes.sort().join(", ");
    },
    varietiesOfOrderAllocations() {
      //console.log("varieties");
      let varieties = [];
      for (let allocation of this.allocations) {
        if (
          !varieties.includes(
            this.$store.getters.getAnyCropById(allocation.crop)?.details
              ?.variety,
          ) &&
          this.$store.getters.getAnyCropById(allocation.crop)?.details
            ?.variety != null
        ) {
          varieties.push(
            this.$store.getters.getAnyCropById(allocation.crop)?.details
              ?.variety,
          );
        }
      }
      return varieties.sort().join(", ");
    },
    sumAllocationAcresByOrderId() {
      return this.$store.getters.getSumAllocationAcresByOrderId(this.orderId);
    },
    estimatedAllocatedQty() {
      //console.log("tpa");
      let tpaEstimates = [];
      let cropType = "";
      let units = this.$store.getters.getGrowerCropOrderById(
        this.orderId,
      )?.units;
      for (let allocation of this.allocations) {
        let crop = this.$store.getters.getAnyCropById(allocation.crop);
        if (crop?.details?.tpa) {
          tpaEstimates.push(parseFloat(crop?.details?.tpa));
        }
        if (!cropType.includes(crop?.crop_type)) {
          cropType += crop?.crop_type;
        }
      }
      // console.log(cropType, units);
      if (tpaEstimates.length > 0) {
        let tons = this.median(tpaEstimates) * this.sumAllocationAcresByOrderId;
        let qty = tons; // default is estimate in tons
        if (units?.toLowerCase() == "bushels" || units?.toLowerCase() == "bu") {
          qty = tonsToBushels(cropType, tons);
        }
        return qty.toFixed(1);
      } else {
        return "Estimate at least one allocated field's TPA to receive an estimate";
      }
    },
  },
  methods: {
    median(values) {
      if (values.length === 0) throw new Error("No inputs");
      values.sort(function (a, b) {
        return a - b;
      });
      var half = Math.floor(values.length / 2);
      if (values.length % 2) return values[half];
      return (values[half - 1] + values[half]) / 2.0;
    },
  },
};
</script>

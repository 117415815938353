<template>
  <text-input
    v-if="currentOrgType === 'buyer'"
    :wideItem="true"
    inputTitle="Seller"
    :display-only="true"
    :display-only-value="currentOrder?.seller_details?.name"
  />
  <text-input
    v-if="currentOrgType === 'grower'"
    :wideItem="true"
    inputTitle="Buyer"
    :display-only="true"
    :display-only-value="currentOrder?.buyer_details?.name"
  />
  <div v-if="currentOrgType === 'grower'" class="col-span-3">
    <p>
      Data Sharing:
      {{ currentOrder?.grower_signature === true ? "Active" : "Inactive" }}
    </p>
    <router-link
      v-if="currentOrder"
      class="text-blue-600 underline visited:text-purple-600 hover:text-blue-800"
      :to="{
        name: 'edit-grower-portal-customer',
        params: { customerId: currentOrder.customer },
      }"
      >Manage</router-link
    >
  </div>
  <div v-else-if="currentOrgType === 'buyer'" class="sm:col-span-3">
    <text-area
      v-if="currentOrder"
      inputTitle="Buyer Notes (not shared with grower)"
      v-model="currentOrder.buyer_notes.note"
      help-text="Examples include external contract IDs or other internal notes. This information is not shared with the grower."
      :wideItem="true"
      :display-only="readOnly"
      :display-only-value="currentOrder?.buyer_notes?.note"
    />
  </div>
  <div v-else class="sm:col-span-6"></div>

  <text-input
    v-if="currentOrder?.crop_type"
    inputTitle="Requested Crop"
    :display-only="true"
    :display-only-value="currentOrder?.crop_type"
    :wideItem="true"
  />
  <text-input
    v-if="currentOrder?.variety"
    inputTitle="Requested Variety"
    :display-only="true"
    :display-only-value="currentOrder?.variety"
    :wideItem="true"
  />
  <text-area
    inputTitle="Delivery Location"
    :display-only="true"
    :display-only-value="currentOrder?.delivery_location"
    :wide-item="true"
  />
  <div v-if="currentOrder?.offer && currentOrder?.capacity">
    <number-formatted-input
      :wideItem="false"
      inputTitle="Offered Qty"
      :required="false"
      :display-only="currentOrgType === 'buyer'"
      :display-only-value="currentOrder?.capacity"
      v-model="currentOrder.capacity"
      help-text="Amount grower is offering to sell. The quantity of the order may be the same or less than this amount."
    />
  </div>
  <number-formatted-input
    v-if="currentOrder?.quantity"
    :wideItem="false"
    inputTitle="Quantity"
    :required="!readOnly && currentOrgType === 'buyer'"
    v-model="currentOrder.quantity"
    :display-only="
      readOnly || (currentOrgType === 'grower' && currentOrder?.offer !== null)
    "
    :display-only-value="currentOrder?.quantity"
    help-text="Quantity that will be accepted for this order."
  />
  <select-dropdown
    :wideItem="false"
    inputTitle="Units"
    :required="false"
    :display-only="true"
    :display-only-value="currentOrder?.units"
  />

  <text-area
    inputTitle="Deliver By"
    :display-only="true"
    :display-only-value="currentOrder?.deliver_by"
    :wide-item="true"
  />
  <div class="sm:col-span-6">
    <text-area
      inputTitle="Terms"
      :display-only="true"
      :display-only-value="currentOrder?.terms"
    />
  </div>
</template>

<script>
import TextInput from "@/components/form/TextInput.vue";
import NumberFormattedInput from "@/components/form/NumberFormattedInput.vue";
import TextArea from "@/components/form/TextArea.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  props: {
    orderId: {
      type: Number,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    // needed to allow a submit button outside the form tags to submit the form
  },
  emits: ["update:order"],
  components: {
    TextInput,
    NumberFormattedInput,
    SelectDropdown,
    TextArea,
  },
  data() {
    return {
      currentOrder: null,
    };
  },
  computed: {
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    currentOrgType() {
      // returns "buyer", "vendor", "grower";
      return this.$store.getters.getCurrentOrgType;
    },
    buyerOrgType() {
      if (this.currentOrgType === "buyer") {
        if (this.currentOrg.feature_flags.includes("bp-controller")) {
          return "controller";
        } else {
          return "affiliate";
        }
      } else {
        return null;
      }
    },
    readyForReadOrder() {
      if (!this.currentOrg) {
        return false;
      }
      if (this.currentOrgType === "grower") {
        if (this.$store.getters.getGrowerCropOrderById(this.orderId)) {
          return true;
        } else {
          return false;
        }
      } else if (this.buyerOrgType === "affiliate") {
        if (this.$store.getters.getBuyerOrderById(this.orderId)) {
          return true;
        } else {
          return false;
        }
      } else if (this.buyerOrgType === "controller") {
        if (this.$store.getters.getControllerOrderById(this.orderId)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    // Form Methods
    readOrder() {
      if (this.currentOrgType === "grower") {
        this.currentOrder = this.$store.getters.getGrowerCropOrderById(
          this.orderId,
        );
        if (this.currentOrder) {
          this.currentOrder.delivery_location =
            this.$store.getters.getGrowerMarketOfferById(
              this.currentOrder.offer,
            )?.delivery_location;
          this.currentOrder.deliver_by =
            this.$store.getters.getGrowerMarketOfferById(
              this.currentOrder.offer,
            )?.deliver_by;
        }
      } else if (this.buyerOrgType === "affiliate") {
        this.currentOrder = this.$store.getters.getBuyerOrderById(this.orderId);
        if (this.currentOrder) {
          this.currentOrder.delivery_location =
            this.$store.getters.getBuyerMarketOfferById(
              this.currentOrder.offer,
            )?.delivery_location;
          this.currentOrder.deliver_by =
            this.$store.getters.getBuyerMarketOfferById(
              this.currentOrder.offer,
            )?.deliver_by;
        }
      } else if (this.buyerOrgType === "controller") {
        this.currentOrder = this.$store.getters.getControllerOrderById(
          this.orderId,
        );
        if (this.currentOrder) {
          this.currentOrder.delivery_location =
            this.$store.getters.getControllerMarketOfferById(
              this.currentOrder.offer,
            )?.delivery_location;
          this.currentOrder.deliver_by =
            this.$store.getters.getControllerMarketOfferById(
              this.currentOrder.offer,
            )?.deliver_by;
        }
      }
      if (this.currentOrder?.listing) {
        if (this.currentOrgType === "grower") {
          this.currentOrder.crop_type =
            this.$store.getters.getGrowerListingById(
              this.currentOrder.listing,
            )?.crop_type;
          this.currentOrder.variety = this.$store.getters.getGrowerListingById(
            this.currentOrder.listing,
          )?.crop_details?.variety;
        } else if (this.buyerOrgType === "affiliate") {
          this.currentOrder.crop_type = this.$store.getters.getBuyerListingById(
            this.currentOrder.listing,
          )?.crop_type;
          this.currentOrder.variety = this.$store.getters.getBuyerListingById(
            this.currentOrder.listing,
          )?.crop_details?.variety;
        } else if (this.buyerOrgType === "controller") {
          this.currentOrder.crop_type = null;
          this.currentOrder.variety = null;
        }
      }
      if (this.currentOrder?.offer) {
        if (this.currentOrgType === "grower") {
          this.currentOrder.crop_type =
            this.$store.getters.getGrowerMarketOfferById(
              this.currentOrder.offer,
            )?.crop;
        } else if (this.buyerOrgType === "affiliate") {
          this.currentOrder.crop_type =
            this.$store.getters.getBuyerMarketOfferById(
              this.currentOrder.offer,
            )?.crop;
        } else if (this.buyerOrgType === "controller") {
          this.currentOrder.crop_type =
            this.$store.getters.getControllerMarketOfferById(
              this.currentOrder.offer,
            )?.crop;
        }
      }
    },
  },
  watch: {
    orderId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        // console.log("the fieldId param changed to ", value, " from ", oldValue)
        this.readOrder();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    currentOrder: {
      handler(newValue) {
        this.$emit("update:order", newValue);
      },
      deep: true,
    },
    readyForReadOrder: {
      handler(newValue) {
        if (newValue && this.orderId) {
          this.readOrder();
        }
      },
      immediate: true,
    },
  },
};
</script>

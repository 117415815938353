<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'contract_name', label: 'Contract' },
          { name: 'crop', label: 'Crop' },
          { name: 'quantity', label: 'Quantity Assigned', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="offers"
        @update:derived-collection="derivedOffers = $event"
      >
        <!-- <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="New"
              :usePlusIcon="true"
              @buttonClick="navigateNewOffer"
            />
          </div>
        </template> -->
      </table-head>
    </template>
    <tbody>
      <tr v-for="offer in derivedOffers" :key="offer.id">
        <table-data td-label="Contract"> {{ offer.contract_name }} </table-data>
        <table-data td-label="Crop">
          {{ offer.crop }}
        </table-data>
        <table-data td-label="Quantity Assigned">
          {{
            offer.quantity?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            })
          }}
          {{ offer.quantity_units }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-market-offer-single',
              params: { offerId: offer.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    // TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedOffers: [],
    };
  },
  computed: {
    offers() {
      // console.log(
      //   "GETTING OFFERS",
      //   this.$store.getters.getBuyerMarketOffers({}),
      // );
      let offers = this.$store.getters.getBuyerMarketOffers({});
      offers = offers.filter((offer) => offer.deleted == null);
      offers = offers.sort((a, b) => {
        return a.id < b.id ? 1 : -1;
      });
      return offers;
    },
  },
  methods: {},
};
</script>

<style></style>

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'buyer_details.name', label: 'Buyer' },
          // { name: 'contract_name', label: 'Contract' },
          { name: 'details.crop_type', label: 'Crop', sorted: false },
          { name: 'quantity', label: 'Quantity' },
          { name: 'units', label: 'Units', filtered: false },
          { name: 'offer_type_label', label: 'Source' },
          { name: 'buyer_signature_label', label: 'Buyer Acceptance' },
          { name: 'growr_signature_label', label: 'Your Acceptance' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="inquiries"
        @update:derived-collection="derivedInquiries = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="inquiry in derivedInquiries" :key="inquiry.id">
        <table-data td-label="Buyer">
          {{ inquiry?.buyer_details.name }}
        </table-data>
        <!-- <table-data td-label="Contract">
          {{ inquiry?.contract_name }}
        </table-data> -->
        <table-data td-label="Crop">
          {{ inquiry?.crop }}
        </table-data>
        <table-data td-label="Quantity" class="text-right">
          {{
            inquiry?.quantity?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            })
          }}
        </table-data>
        <table-data td-label="Units">
          {{ inquiry?.units }}
        </table-data>
        <table-data td-label="Source">
          {{ inquiry?.offer_type_label }}
        </table-data>
        <table-data td-label="Buyer Acceptance">
          {{ inquiry?.buyer_signature_label }}
        </table-data>
        <table-body-cell-pill
          td-label="Your Acceptance"
          :hiddenOnSmallScreens="true"
          :cellData="inquiry?.grower_signature_label"
          :pillColorIndex="inquiry?.grower_signature ? 0 : 1"
        />
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'grower-portal-produce-inqury',
              params: { inquiryId: inquiry.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Manage Inquiry
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableBodyCellPill from "@/components/table/TableBodyCellPill.vue";
export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableBodyCellPill,
  },
  data() {
    return {
      derivedInquiries: [],
    };
  },
  computed: {
    inquiries() {
      let derived = this.$store.getters.getGrowerCropOrders(["Inquiry"]);
      derived = derived.filter((inquiry) => {
        return inquiry.offer == null;
      });
      derived = derived.map((inquiry) => {
        if (inquiry.buyer_signature !== true) {
          inquiry.buyer_signature_label = "Awaiting Buyer";
        } else {
          inquiry.buyer_signature_label = "Accepted";
        }
        if (inquiry.grower_signature !== true) {
          inquiry.grower_signature_label = "Needs Acceptance";
        } else {
          inquiry.grower_signature_label = "Accepted";
        }
        if (inquiry.listing !== null) {
          inquiry.offer_type_label = "Buyer";
        } else {
          inquiry.offer_type_label = "You";
        }
        inquiry.crop = inquiry?.details?.crop;
        if (
          inquiry.offer &&
          this.$store.getters.getGrowerMarketOfferById(inquiry.offer)
        ) {
          inquiry.contract_name = this.$store.getters.getGrowerMarketOfferById(
            inquiry.offer,
          ).contract_name;
        }
        return inquiry;
      });
      derived = derived.sort((a, b) => {
        return b.id - a.id;
      });

      return derived;
    },
    offerTypeOptions() {
      let distinct = [
        "Outgoing Inquiry To Sell Crop",
        "Incoming Inquiry To Buy Crop",
      ];
      return distinct;
    },
  },
  methods: {},
};
</script>

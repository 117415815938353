<template>
  <template v-if="enabled">
    <slot></slot>
  </template>
</template>

<script>
export default {
  props: ["requires", "requiresOne"],
  data() {
    // const hasFeatureFlag = (flag) => {
    //   return (
    //     this.$store.getters.getCurrentOrg?.feature_flags || new Array()
    //   ).includes(flag);
    // };
    // let enabled = false;
    // if (this.requires == "allow") {
    //   enabled = true;
    // } else if (this.requires) {
    //   let allFlags = this.requires.split(",");
    //   enabled = true;
    //   allFlags.forEach((flag) => {
    //     if (!hasFeatureFlag(flag.trim())) enabled = false;
    //   });
    //   // if (hasFeatureFlag(this.requires)) enabled = true;
    // } else if (this.requiresOne) {
    //   this.requiresOne.forEach((element) => {
    //     if (hasFeatureFlag(element)) enabled = true;
    //   });
    // }
    // return { enabled };
    return {};
  },
  computed: {
    enabled() {
      const hasFeatureFlag = (flag) => {
        return (
          this.$store.getters.getCurrentOrg?.feature_flags || new Array()
        ).includes(flag);
      };
      let enabled = false;
      if (this.requires == "allow") {
        enabled = true;
      } else if (this.requires) {
        let allFlags = this.requires.split(",");
        enabled = true;
        allFlags.forEach((flag) => {
          if (!hasFeatureFlag(flag.trim())) enabled = false;
        });
        // if (hasFeatureFlag(this.requires)) enabled = true;
      } else if (this.requiresOne) {
        this.requiresOne.forEach((element) => {
          if (hasFeatureFlag(element)) enabled = true;
        });
      }
      return enabled;
    },
  },
};
</script>

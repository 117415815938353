<template>
  <div class="ml-2 p-2 sm:col-span-6" v-if="currentPremium">
    <div
      class="text-md my-2 text-left font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
    >
      Premium Requirements
    </div>
    Record the following:
    <p
      v-for="req in interpretPremium(currentPremium, true)"
      :key="req"
      class="text-sm leading-6 text-gray-900"
    >
      {{ req }}
    </p>
  </div>
  <div
    v-else
    class="text-md my-2 ml-2 text-left font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    No Premium Attached
  </div>
</template>
<script>
import { interpretPremium } from "@/components/composables/growerPremiumUtils.js";
export default {
  components: {},
  props: {
    currentPremium: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    interpretPremium,
  },
};
</script>

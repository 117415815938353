import client from "@/api/parcel/api.js";

export default {
  state() {
    return { memberOrgs: [] };
  },
  mutations: {
    SET_MEMBER_ORGS(state, data) {
      state.memberOrgs = data;
    },
    RESET_MEMBER_ORGS(state) {
      state.memberOrgs = [];
    },
    REMOVE_MEMBER_ORG(state, memberOrg) {
      state.memberOrgs = state.memberOrgs.filter((a) => a.id !== memberOrg);
    },
  },
  actions: {
    async getMemberOrgs({ commit }) {
      const data = await client.orgs.readMemberOrgs();
      if (data) {
        commit("SET_MEMBER_ORGS", data.member_orgs);
      }
    },
    async deleteMemberOrg({ commit }, memberOrg) {
      const data = await client.orgs.deleteMemberOrg(memberOrg.id);
      if (data) {
        commit("REMOVE_MEMBER_ORG", data);
        return data;
      }
    },
  },
  getters: {
    getMemberOrgById: (state) => (orgId) => {
      return state.memberOrgs.find((org) => org.id === orgId);
    },
    getAllMemberOrgs: (state) => {
      return state.memberOrgs;
    },
  },
};

<template>
  <page-card headingTitle="Add Grower">
    <template #buttonArea>
      <submit-button
        buttonText="Save"
        :marginLeft="false"
        :formId="newGrowerFormId"
      />
    </template>
    <template #cardDetails>
      <grower-form :formId="newGrowerFormId" />
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import GrowerForm from "@/layouts/buyer-portal/bp-affiliate/growers/GrowerForm.vue";
export default {
  components: {
    PageCard,
    SubmitButton,
    GrowerForm,
  },

  data() {
    return {
      newGrowerFormId: "new-grower-form",
    };
  },
  computed: {},
  methods: {},
};
</script>

export default {
  state() {
    return {
      show: false,
      parentRoute: null,
      parentId: null,
    };
  },
  mutations: {
    SHOW(state) {
      state.show = true;
    },
    HIDE(state) {
      state.show = false;
    },
    SET_PARENT_ROUTE(state, route) {
      state.parentRoute = route;
    },
    SET_PARENT_ID(state, id) {
      state.parentId = id;
    },
  },
  actions: {
    showView() {
      this.commit("SHOW");
    },
    hideView() {
      this.commit("HIDE");
    },
    toggleView() {
      // console.log("toggleView", this.state);
      this.state.viewState.show ? this.commit("HIDE") : this.commit("SHOW");
    },
    setParentRoute({ commit }, route) {
      commit("SET_PARENT_ROUTE", route);
    },
    setParentId({ commit }, id) {
      commit("SET_PARENT_ID", id);
    },
  },
  getters: {
    getViewState: (state) => {
      return state.show;
    },
    getParentRoute: (state) => {
      return state.parentRoute;
    },
    getParentId: (state) => {
      return state.parentId;
    },
  },
};

<template>
  <delete-modal
    :open="showDeleteModal"
    modalTitle="Delete Inquiry"
    modalDescription="Are you sure you want to permanently delete this inquiry?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="deleteCropInquiry"
  />
  <slot-modal
    :open="showAgreementModal"
    modalTitle="Grower Agreement"
    modalDescription=""
    @closeModal="this.showAgreementModal = false"
  >
    <!-- <template #icon
      ><PaperAirplaneIcon class="text-black-600 h-6 w-6" aria-hidden="true"
    /></template> -->
    <template #content>
      <view-crop-order-agreement :offer="currentOffer" :order="currentOrder" />
    </template>
  </slot-modal>
  <page-card
    :headingTitle="
      currentOrder?.quantity +
      ' ' +
      currentOffer?.quantity_units +
      ' of ' +
      currentOrder?.details?.crop +
      ' to ' +
      currentOrder?.buyer_details?.name
    "
    :headingDescription="
      'Order # ' + $route.params.orderId + ' | ' + currentStatus
    "
  >
    <template #buttonArea>
      <base-button
        buttonText="Delete"
        :redButton="true"
        @buttonClick="intializeDeleteModal"
        v-if="currentOrder?.status == 'Inquiry'"
      />
      <base-button
        buttonText="Accept"
        :marginLeft="true"
        @buttonClick="acceptOrder"
        v-if="currentOrder?.grower_signature == false"
      />
      <base-button
        buttonText="View Agreement"
        :marginLeft="true"
        @buttonClick="this.showAgreementModal = true"
        v-if="currentOrder?.grower_signature == true && currentOrder?.offer"
      />
      <!-- <base-button
        buttonText="View Agreement"
        :marginLeft="true"
        @buttonClick="
          $router.push({
            name: 'grower-view-crop-order-agreement',
            params: {
              offerId: currentOrder?.offer,
              orderId: $route.params.orderId,
            },
          })
        "
        v-if="currentOrder?.grower_signature == true && currentOrder?.offer"
      /> -->
      <base-button
        buttonText="Finalize Order"
        :marginLeft="true"
        @buttonClick="completeOrder"
        v-if="
          currentOrder?.status == 'In Progress' &&
          currentOrder?.grower_signature == true
        "
      />
      <!-- <base-button :marginLeft="true" buttonText="Save" /> Not used, let's not show it-->
    </template>
    <template #cardDetails>
      <div class="sm:col-span-2">
        <detail-card
          :title="'Allocations'"
          :titleStat="allocationData.count + '  fields'"
          :statusText="
            allocationData.count > 0
              ? allocationData.anyZero
                ? 'Missing Acres'
                : 'Assigned'
              : 'Unassigned'
          "
          :statusRed="allocationData.count > 0 ? false : true"
          :statusYellow="allocationData.count > 0 && allocationData.anyZero"
          :primaryStat="{
            text: allocationData.acres_allocated + ' Allocated Acres',
            icon: 'TemplateIcon',
          }"
          :secondaryStats="[
            {
              text:
                allocationData.estimated_qty +
                ' ' +
                currentOffer?.quantity_units +
                ' Estimated Qty',
              icon: 'PresentationChartLineIcon',
            },
          ]"
          :buttonText="'Edit Fields'"
          @detailCardClick="tabChanged(0)"
        />
      </div>
      <div class="sm:col-span-2">
        <detail-card
          v-if="currentOffer?.premiums"
          :title="'Premiums'"
          :titleStat="
            premiumData.nonFixedPremiums == premiumData.totalPremiums
              ? 'Manual Accounting Required'
              : '$' + premiumData.totalValue + ' opportunity'
          "
          :statusText="
            premiumData.completedPremiums == premiumData.totalPremiums
              ? 'Completed'
              : 'Incomplete'
          "
          :statusRed="
            premiumData.completedPremiums == premiumData.totalPremiums
              ? false
              : true
          "
          :primaryStat="{
            text: '$' + currentOrderValue + ' est. earnings',
            icon: 'CurrencyDollarIcon',
          }"
          :secondaryStats="[
            {
              text:
                premiumData.completedFields +
                ' of ' +
                premiumData.totalFields +
                ' fields completed',
              icon: 'PencilIcon',
            },
          ]"
          :buttonText="'Edit Records'"
          @detailCardClick="tabChanged(1)"
        />
        <detail-card
          v-else
          :title="'Premiums'"
          :titleStat="'No premiums provided for this offer'"
          :statusText="'N/A'"
          :statusRed="false"
          :primaryStat="{
            text: 'N/A',
            icon: 'CurrencyDollarIcon',
          }"
          :secondaryStats="[
            {
              text: 'N/A',
              icon: 'PencilIcon',
            },
          ]"
          :buttonText="''"
          @detailCardClick="tabChanged(0)"
        />
      </div>
      <div class="sm:col-span-2">
        <detail-card
          :title="'Deliveries'"
          :titleStat="
            currentOrder?.quantity + ' ' + currentOrder?.units + ' to deliver'
          "
          :statusText="
            deliveryData?.totalDelivered >= currentOrder?.quantity ||
            currentOrder?.units == 'acres'
              ? 'Complete'
              : 'Incomplete'
          "
          :statusRed="
            deliveryData?.totalDelivered >= currentOrder?.quantity ||
            currentOrder?.units == 'acres'
              ? false
              : true
          "
          :primaryStat="{
            text: currentOffer?.deliver_by
              ? 'Deliver By : ' + currentOffer?.deliver_by
              : 'Deliver By : N/A',
            icon: 'CalendarIcon',
          }"
          :secondaryStats="[
            {
              text:
                deliveryData?.totalDelivered +
                ' ' +
                (currentOrder?.units != 'acres'
                  ? currentOrder?.units + ' delivered'
                  : currentOrderDeliveries[0]?.quantity_units + ' delivered'),
              icon: 'TruckIcon',
            },
          ]"
          :buttonText="'Edit Deliveries'"
          @detailCardClick="tabChanged(2)"
        />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Fields
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Records
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Deliveries
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Order Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Offer Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
            <Tab
              as="template"
              v-slot="{ selected }"
              v-if="currentOrder?.status != 'Internal'"
            >
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-4">
            <!-- Allocations content will be handled by the AllocationsManager component -->
          </TabPanel>
          <TabPanel as="dl" class="pt-4">
            <!-- Premiums content will be handled by the PremiumsManager component -->
          </TabPanel>

          <TabPanel as="dl" class="pt-4">
            <span
              class="text-md my-2 text-left font-medium tracking-wider text-gray-500 sm:col-span-6"
            >
              Record deliveries which fulfill this order
            </span>
            <div class="mb-4 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
              <CropHarvestAggregate
                :orderId="parseInt($route.params.orderId)"
                perspective="grower"
              />
              <DeliveryAggregate :orderId="parseInt($route.params.orderId)" />
            </div>
            <crop-delivery-table
              :deliveries="currentOrderDeliveries"
              :orderId="parseInt(orderId)"
            ></crop-delivery-table>
          </TabPanel>
          <TabPanel as="dl" class="pt-4">
            <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
              <crop-order-display
                v-if="dataIsLoaded"
                :orderId="parseInt($route.params.orderId)"
                @update:order="updateOrder()"
              />
            </div>
          </TabPanel>
          <TabPanel as="dl" class="pt-4">
            <div class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
              <contract-form-details
                v-if="dataIsLoaded"
                v-model="currentOffer"
                :readOnly="true"
              />
            </div>
          </TabPanel>
          <TabPanel as="dl" class="pt-4">
            <view-attachments-table
              v-if="currentOrder?.status != 'Internal'"
              :attachments="attachments"
            />
            <add-attachments-table
              v-else
              :attachments="currentOrder?.attachments"
            />
          </TabPanel>
          <TabPanel
            as="dl"
            class="pt-4"
            v-if="currentOrder?.status != 'Internal'"
          >
            <chat-single-view :thread-id="currentOrder?.details?.threadId" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
      <allocations-manager
        v-show="selectedTab == 0"
        :orderId="parseInt($route.params.orderId)"
        @updateAllocationData="setAllocationData"
      />
      <premiums-manager
        v-show="selectedTab == 1 && currentOffer?.premiums"
        :orderId="parseInt($route.params.orderId)"
        @updatePremiumData="setPremiumData"
      />
    </template>
  </page-card>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import CropOrderDisplay from "@/layouts/buyer-grower/crop-orders/CropOrderDisplay.vue";
import ViewCropOrderAgreement from "@/pages/buyer-portal/bp-affiliate/orders/ViewCropOrderAgreement.vue";

// import CropPremiumDisplay from "@/layouts/buyer-grower/crop-orders/CropPremiumDisplay.vue";
import CropDeliveryTable from "@/layouts/grower-portal/sell/CropDeliveryTable.vue";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";
import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
// import AllocationsAggregate from "@/layouts/grower-portal/sell/orders/AllocationAggregate.vue";
import DeliveryAggregate from "@/layouts/grower-portal/sell/orders/DeliveryAggregate.vue";
import CropHarvestAggregate from "@/layouts/common/orders/CropHarvestAggregate.vue";
// import AllocationsTable from "@/layouts/grower-portal/sell/orders/AllocationsTable.vue";
import AllocationsManager from "@/layouts/grower-portal/sell/orders/AllocationsManager.vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import ChatSingleView from "@/layouts/common/ChatSingleView.vue";
import DetailCard from "@/components/cards/DetailCard.vue";
import PremiumsManager from "@/layouts/grower-portal/sell/orders/PremiumsManager.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import SlotModal from "@/components/modals/SlotModal.vue";

import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    BaseButton,
    CropOrderDisplay,
    ViewCropOrderAgreement,
    ContractFormDetails,
    // CropPremiumDisplay,
    AddAttachmentsTable,
    ViewAttachmentsTable,
    // AllocationsTable,
    AllocationsManager,
    // AllocationsAggregate,
    DeliveryAggregate,
    CropHarvestAggregate,
    ChatSingleView,
    CropDeliveryTable,
    DetailCard,
    PremiumsManager,
    DeleteModal,
    SlotModal,
  },
  data() {
    // console.log("CROP ORDER");
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return {
      showDeleteModal: false,
      showAgreementModal: false,
      selectedTab,
      allocationData: {},
      premiumData: {},
    };
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        // this.$store.getters.getGrowerMarketOfferById(this.orderId) &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId) &&
        this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    attachments() {
      let attachments = this.currentOrder?.attachments;
      let offerAttachments = this.currentOffer?.attachments;
      if (attachments && offerAttachments) {
        attachments = attachments.concat(offerAttachments);
      }
      return attachments;
    },
    currentOrder() {
      if (!this.dataIsLoaded) {
        return null;
      }
      let order = this.$store.getters.getGrowerCropOrderById(this.orderId);
      return order;
      // let test = this.$store.getters.getGrowerCropOrderById(this.orderId);
      // console.log("currentOrder", test);
      // return test;
    },
    currentStatus() {
      let status = "";
      if (this.currentOrder?.buyer_signature) {
        status += "Approved and ";
      } else if (
        this.currentOrder?.buyer_signature == false &&
        this.currentOrder?.status == "Inquiry"
      ) {
        status += "Pending ";
      }
      status += this.currentOrder?.status;
      if (status == "Internal") {
        return "Internal";
      }
      return status;
    },
    currentOrderDeliveries() {
      if (!this.dataIsLoaded) {
        return null;
      }
      return this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId);
    },
    currentOrderValue() {
      if (!this.dataIsLoaded) {
        return null;
      }
      let base_premium = this.currentOffer?.premiums.find(
        (premium) => premium.id == this.currentOrder?.premium,
      )?.base_premium;
      let quantity = 0;
      switch (this.currentOrder?.units) {
        case "acres":
          quantity = this.allocationData.acres_allocated;
          break;
        default:
          quantity = this.allocationData.estimated_qty;
      }
      if (quantity * base_premium > this.premiumData.totalValue) {
        return this.premiumData.totalValue;
      }
      return quantity * base_premium;
    },
    deliveryData() {
      if (!this.dataIsLoaded) {
        return {};
      }
      return {
        // sum of all delivery.quantity_delivered
        totalDelivered: this.currentOrderDeliveries.reduce(
          (acc, delivery) => acc + delivery.quantity_delivered,
          0,
        ),
      };
    },
    offerId() {
      return this.currentOrder?.offer;
    },
    currentOffer() {
      if (!this.dataIsLoaded) {
        return null;
      }
      return this.$store.getters.getGrowerMarketOfferById(this.offerId);
    },
  },
  methods: {
    // Modal Methods
    intializeDeleteModal() {
      this.showDeleteModal = true;
    },
    cancelDelete() {
      this.showDeleteModal = false;
    },
    // Modal Methods

    async deleteCropInquiry() {
      this.showDeleteModal = false;
      // console.log("DELETE");
      await this.$store.dispatch("deleteGrowerCropOrder", {
        id: this.$route.params.orderId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-orders" });
      }
    },
    async deleteProduceOrder() {
      this.showDeleteModal = false;
      // console.log("DELETE");
      let allosToDelete = this.$store.getters.getGrowerAllocationsByOrderId(
        this.$route.params.orderId,
      );
      for (let allo of allosToDelete) {
        this.$store.dispatch("deleteGrowerAllocation", { id: allo.id });
      }
      await this.$store.dispatch("deleteGrowerCropOrder", {
        id: this.$route.params.orderId,
      });
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "grower-portal-produce-orders" });
      }
    },
    acceptOrder() {
      this.currentOrder.status = "Accepted";
      this.currentOrder.grower_signature = true;
      this.$store.dispatch("updateGrowerCropOrder", {
        id: this.orderId,
        grower_signature: true,
      });
    },
    completeOrder() {
      this.$router.push({
        name: "grower-portal-finalize-offer-order",
        params: { orderId: this.orderId },
      });
      // this.$store.dispatch("updateGrowerCropOrder", {
      //   id: this.orderId,
      //   status: "Completed",
      // });
    },
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      // set the query param to the selected tab
      this.$router.push({
        query: { tab: index },
      });
    },
    setAllocationData(data) {
      this.allocationData = data;
    },
    setPremiumData(data) {
      this.premiumData = data;
    },
    updateOrder() {
      let order = this.currentOrder;
      let updatedOrder = {
        id: order.id,
        estimated_price: order.estimated_price,
        units: order.units,
        terms: order.terms,
        details: order.details,
        capacity: order.capacity,
        premium: order.premium,
        offer: order.offer,
      };
      this.$store.dispatch("updateGrowerCropOrder", updatedOrder);
    },
  },
};
</script>

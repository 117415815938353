<template>
  <slot-modal
    :open="showAgreementModal"
    modalTitle="Grower Agreement"
    modalDescription=""
    @closeModal="this.showAgreementModal = false"
  >
    <!-- <template #icon
      ><PaperAirplaneIcon class="text-black-600 h-6 w-6" aria-hidden="true"
    /></template> -->
    <template #content>
      <view-crop-order-agreement :offer="currentOffer" />
    </template>
  </slot-modal>
  <page-card headingTitle="New Order">
    <template #buttonArea>
      <base-button
        buttonText="View Agreement"
        :marginLeft="true"
        :disabled="!currentOffer || !offerPremiumId || !growerId"
        @buttonClick="showAgreementModal = true"
      />
      <submit-button
        buttonText="Save"
        :marginLeft="true"
        formId="newOfferOrderForm"
      />
    </template>
    <template #cardDetails>
      <form
        @submit.prevent="createNewOfferOrder"
        id="newOfferOrderForm"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <!-- Select Search for Grower -->
        <select-search
          :wideItem="true"
          inputTitle="Grower"
          :required="true"
          :selectOptions="growerOptions"
          v-model="growerId"
        />
        <!-- Select Search for Offer -->
        <select-search
          :wideItem="true"
          inputTitle="Offer"
          :required="true"
          :selectOptions="offerOptions"
          v-model="offerId"
        />
        <!-- Select Dropdown for Offer Premium -->
        <select-dropdown
          :disabled="!offerId"
          :wideItem="true"
          :required="true"
          inputTitle="Premium"
          :selectOptions="premiumOptions"
          v-model="offerPremiumId"
        />
        <!-- Grower Capacity -->
        <!-- <number-input
          :wideItem="true"
          inputTitle="Grower Capacity"
          :required="true"
          v-model="orderCapacity"
          helpText="The maximum amount the grower can provide."
        /> -->
        <!-- Order Quantity -->
        <number-input
          :wideItem="true"
          inputTitle="Order Quantity"
          :required="true"
          v-model="orderQuantity"
          helpText="The quantity that will be accepted from the grower."
        />
        <!-- Order Units -->
        <text-input
          :wideItem="true"
          inputTitle="Order Units"
          :displayOnly="true"
          :displayOnlyValue="currentOffer?.quantity_units"
        />
        <CheckboxInput
          :wideItem="true"
          inputTitle="I have a signed grower agreement for this order."
          :required="true"
          v-model="agreementCollected"
          helpText="Select View Agreement, print and send to grower for signature. Please add as an attachment to the order if signed agreement is in electronic format."
        />
      </form>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import SelectSearch from "@/components/form/SelectSearch.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import { summarizePremium } from "@/components/composables/scriptUtils";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import { useToast } from "vue-toastification";
import ViewCropOrderAgreement from "@/pages/buyer-portal/bp-affiliate/orders/ViewCropOrderAgreement.vue";
import SlotModal from "@/components/modals/SlotModal.vue";

export default {
  components: {
    PageCard,
    TextInput,
    NumberInput,
    SelectSearch,
    SelectDropdown,
    SubmitButton,
    BaseButton,
    CheckboxInput,
    ViewCropOrderAgreement,
    SlotModal,
  },
  data() {
    return {
      growerId: this.$route.query.growerId || null,
      offerId: this.$route.query.offerId || null,
      offerPremiumId: null,
      //   orderCapacity: null,
      orderQuantity: null,
      orderDetails: {},
      agreementCollected: false,
      toast: useToast(),
      showAgreementModal: false,
    };
  },
  computed: {
    offers() {
      let offers = this.$store.getters.getBuyerMarketOffers();
      offers = offers.filter((o) => o.published === true);
      return offers;
    },
    growers() {
      return this.$store.getters.getAllMemberOrgs;
    },
    offerOptions() {
      let offerOptions = [];
      for (let offer of this.offers) {
        let option = {
          label: offer.contract_name,
          value: offer.id,
        };
        offerOptions.push(option);
      }
      return offerOptions;
    },
    growerOptions() {
      let growerOptions = [];
      for (let grower of this.growers) {
        let option = {
          label: grower.name,
          value: grower.id,
        };
        growerOptions.push(option);
      }
      return growerOptions;
    },
    offerOrderForSubmission() {
      let submission = {};
      submission.offer = this.offerId;
      submission.grower = this.growerId;
      submission.quantity = this.orderQuantity;
      submission.units = this.currentOffer?.quantity_units;
      submission.premium = this.offerPremiumId;
      submission.buyer_notes = {};
      submission.details = {
        agreementCollected: this.agreementCollected,
      };
      submission.terms = "";
      return submission;
    },
    currentOffer() {
      return this.$store.getters.getBuyerMarketOfferById(this.offerId);
    },
    currentGrower() {
      return this.$store.getters.getSharedOrgById(this.growerId);
    },
    premiumOptions() {
      return this.currentOffer?.premiums.map((p) => {
        return {
          label:
            summarizePremium(p) +
            " - " +
            p.validation_records.length +
            " requirement(s)",
          value: p.id,
        };
      });
    },
  },
  methods: {
    async createNewOfferOrder() {
      if (this.agreementCollected === false) {
        this.toast.error(
          "Please confirm that you have a signed grower agreement.",
          {
            timeout: 1500,
            hideProgressBar: true,
          },
        );
        return;
      }
      let createdOrder = await this.$store.dispatch(
        "createBuyerCropOrder",
        this.offerOrderForSubmission,
      );
      this.$router.push({
        name: "buyer-view-order",
        params: { orderId: createdOrder.id },
      });
    },
  },
  watch: {},
};
</script>

<template>
  <div class="sm:col-span-6">
    <span class="pt-4 text-sm text-gray-900">
      If all necessary records have been entered for a given field, click the
      slider to approve that field.
    </span>
    <table-card v-if="allocations">
      <template #header>
        <table-head
          :columns="[
            {
              name: 'field_data.properties.name',
              label: 'Field',
              filtered: false,
              sorted: true,
            },
            {
              name: 'validatedAgainstSelectedPremium',
              label: 'All Premium Requirements Met',
              filtered: false,
              sorted: false,
            },
          ]"
          :collection="allocations"
          @update:derived-collection="derivedAllocations = $event"
          :default-sort="{
            'field_data.properties.name': 'asc',
          }"
        >
        </table-head>
      </template>

      <tbody>
        <tr v-for="allo in derivedAllocations" :key="allo.id">
          <table-data td-label="Type">
            {{ allo.field_data?.properties?.name }}
          </table-data>
          <table-data class="text-left" td-label="Field Validated">
            <toggle-button
              :enabled="allo.validatedAgainstSelectedPremium"
              @toggle="toggleAllocationValidationAgainstPremium(allo.id)"
            />
          </table-data>
        </tr>
      </tbody>
    </table-card>
  </div>
</template>
<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import ToggleButton from "@/components/buttons/ToggleButton.vue";
export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    ToggleButton,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      derivedAllocations: [],
    };
  },
  computed: {
    // CORE DATA
    currentOrder() {
      if (!this.orderId) {
        return null;
      }
      // use this.orderId to fetch the order
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    currentOffer() {
      if (!this.currentOrder) {
        return null;
      }
      // use this.orderId to fetch the offer
      return this.$store.getters.getGrowerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    allocations() {
      if (!this.orderId || !this.currentOffer) {
        return null;
      }
      // use this.orderId to fetch the allocations
      // for each allocation, attach the crop_data and field_data
      let allocations = this.$store.getters.getGrowerAllocationsByOrderId(
        this.orderId,
      );
      if (!this.currentOffer?.premiums) {
        return allocations;
      }
      return allocations.map((allo) => {
        return {
          ...allo,
          field_data: this.$store.getters.getFieldByCropId(allo.crop),
          // currentOrderValidations equals allo.validations_met filtered down to validation ids that are in the currentOffer.premiums
          currentOrderValidations: allo.validations_met.filter((val) =>
            this.currentOffer.premiums.map((prem) => prem.id).includes(val),
          ),
          validatedAgainstSelectedPremium: allo.validations_met.includes(
            this.currentPremium?.id,
          ),
        };
      });
    },
    crops() {
      if (!this.allocations || !this.currentOffer) {
        return null;
      }
      let fieldIds = [];
      // loop through allocations, use the getFieldIdByCropId getter to get the field id, and push it to fieldIds
      for (let allo of this.allocations) {
        fieldIds.push(this.$store.getters.getFieldIdByCropId(allo.crop));
      }
      // loop through fieldIds, use the getCropsByFieldId getter to get the crops
      // the arguments for the getter are the field id, the current year - this.currentOffer.additional_prior_year_records, and the current year
      let crops = [];
      for (let fieldId of fieldIds) {
        // get the crops for the fieldId, give each crop field_data via the getFieldByCropId getter, and push them to the crops array as a single array
        for (let crop of this.$store.getters.getCropsByFieldId(fieldId)) {
          crops.push({
            ...crop,
            harvest_year: new Date(crop.harvest_range_end).getFullYear(),
            field_data: this.$store.getters.getFieldById(fieldId),
            records: this.$store.getters.getRecordsByCrop(crop.id),
            relatedAllocationId: this.allocations.find(
              (allo) => allo.field_data.id == crop.field,
            )?.id,
            // set validated to true if the related allocation.validations_met includes the currentPremium id
            validated: this.allocations
              .find((allo) => allo.field_data.id == crop.field)
              ?.validations_met.includes(this.currentPremium.id),
          });
        }
      }
      return crops;
    },
    // Premiums
    //
    premiumOptions() {
      if (!this.currentOffer?.premiums) {
        return [];
      }
      return this.currentOffer.premiums.map((premium) => ({
        // Put in better premium names
        label: premium.type,
        value: premium.id,
      }));
    },
    currentPremium() {
      if (!this.currentOffer?.premiums) {
        return null;
      }
      // return the premium from this.currentOffer.premiums that matches the currentPremium id
      let premium = this.currentOffer?.premiums.find(
        (premium) => premium.id == this.currentOrder.premium,
      );
      return premium;
    },
  },
  methods: {
    toggleAllocationValidationAgainstPremium(alloId) {
      if (!this.currentPremium) {
        return;
      }

      // Fetch the full allocation object from the store
      let allo = this.$store.getters.getGrowerAllocationById(alloId);
      // console.log("allo", alloId, allo);
      // Clone the validations_met array to avoid mutating the original
      let allo_validations = [...allo.validations_met];

      // Check if the currentPremium.id is already in the validations_met array
      const premiumIndex = allo_validations.indexOf(this.currentPremium.id);

      if (premiumIndex !== -1) {
        // If the currentPremium.id exists in the array, remove it
        allo_validations.splice(premiumIndex, 1);
      } else {
        // If the currentPremium.id doesn't exist in the array, add it
        allo_validations.push(this.currentPremium.id);
      }
      // udpate the local copy first, so we can see the change in the UI
      allo.validations_met = allo_validations;
      allo.validatedAgainstSelectedPremium = allo_validations.includes(
        this.currentPremium.id,
      );
      // Dispatch the updated allocation with the modified validations_met array
      this.$store.dispatch("updateGrowerAllocation", {
        ...allo, // Spread the entire allocation object
        validations_met: allo_validations, // Replace the validations_met array
      });
    },
  },
};
</script>

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          {
            name: 'contract_name',
            label: 'Contract',
            hidden: offerId ? true : false,
          },
          { name: 'seller_details.name', label: 'Grower' },
          { name: 'details.crop_type', label: 'Crop', sorted: false },
          { name: 'quantity', label: 'Quantity' },
          { name: 'units', label: 'Units', filtered: false },
          { name: 'offer_type_label', label: 'Source' },
          { name: 'grower_signature_label', label: 'Grower Acceptance' },
          { name: 'buyer_signature_label', label: 'Your Acceptance' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="inquiries"
        @update:derived-collection="derivedInquiries = $event"
      >
      </table-head>
    </template>
    <tbody>
      <tr v-for="inquiry in derivedInquiries" :key="inquiry.id">
        <table-data td-label="Contract" v-if="!offerId">
          {{ inquiry?.contract_name }}
        </table-data>
        <table-data td-label="Grower">
          {{ inquiry?.seller_details.name }}
        </table-data>
        <table-data td-label="Crop">
          {{ inquiry?.crop }}
        </table-data>
        <table-data td-label="Quantity" class="text-right">
          {{
            inquiry?.quantity?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            })
          }}
        </table-data>
        <table-data td-label="Units">
          {{ inquiry?.units }}
        </table-data>
        <table-data td-label="Source">
          {{ inquiry?.offer_type_label }}
        </table-data>

        <table-data td-label="Grower Acceptance">
          {{ inquiry?.grower_signature_label }}
        </table-data>
        <table-body-cell-pill
          td-label="Your Acceptance"
          :hiddenOnSmallScreens="true"
          :cellData="inquiry?.buyer_signature_label"
          :pillColorIndex="inquiry?.buyer_signature ? 0 : 1"
        />
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-inquiry-single',
              params: { inquiryId: inquiry.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
  <!-- <delete-modal
    :open="modalIsActive"
    modalTitle="Cancel Inquiry"
    modalDescription="Are you sure you want to permanently remove this inquiry?"
    @clickCancel="cancelDelete"
    @clickConfirmDelete="cancelInquiry"
  /> -->
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import TableBodyCellPill from "@/components/table/TableBodyCellPill.vue";
// import DeleteModal from "@/components/modals/DeleteModal.vue";
export default {
  components: {
    TableCard,
    TableHead,
    TableData,
    TableBodyCellPill,
  },
  props: {
    offerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      selectedTab: 0,
      derivedInquiries: [],
      inquiriesFilter: function (o) {
        if (o.status === "Inquiry") {
          return true;
        } else {
          return false;
        }
      },
    };
  },
  computed: {
    inquiries() {
      let derived = this.$store.getters.getDerivedBuyerCropOrders({
        filterFunction: this.inquiriesFilter,
      });
      if (this.offerId) {
        derived = derived.filter((inquiry) => inquiry.offer === this.offerId);
      }
      derived = derived.map((inquiry) => {
        if (inquiry.buyer_signature !== true) {
          inquiry.buyer_signature_label = "Needs Acceptance";
        } else {
          inquiry.buyer_signature_label = "Accepted";
        }
        if (inquiry.grower_signature !== true) {
          inquiry.grower_signature_label = "Awaiting Grower";
        } else {
          inquiry.grower_signature_label = "Accepted";
        }
        if (inquiry.listing !== null) {
          inquiry.offer_type_label = "You";
        } else {
          inquiry.offer_type_label = "Grower";
        }
        inquiry.crop = inquiry?.details?.crop;
        inquiry.contract_name = this.$store.getters.getBuyerMarketOfferById(
          inquiry.offer,
        )?.contract_name;
        return inquiry;
      });
      derived = derived.sort((a, b) => {
        return b.id - a.id;
      });
      // console.log("inquiries", derived);
      return derived;
    },
    offerTypeOptions() {
      let distinct = [
        "Outgoing Inquiry To Buy Crop",
        "Incoming Inquriy To Sell Crop",
      ];
      return distinct;
    },
  },
  methods: {
    // cancelInquiry() {
    //   let order = this.$store.getters.getBuyerOrderById(this.currentInquiry.id);
    //   order.status = "Canceled";
    //   this.$store.dispatch("deleteBuyerCropOrder", order);
    //   this.modalIsActive = false;
    // },
    // intializeDeleteModal(inquiry) {
    //   this.currentInquiry = inquiry;
    //   this.modalIsActive = true;
    // },
    // cancelDelete() {
    //   this.modalIsActive = false;
    //   this.currentInquiry = null;
    // },
  },
};
</script>

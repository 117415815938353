import client from "@/api/parcel/api.js";
import { universalGetter } from "@/components/composables/universalGetter";

export default {
  state() {
    return {
      growerAllocations: [],
      // growerAllocationRecords: [],
    };
  },
  mutations: {
    SET_GROWER_CROP_ALLOCATIONS(state, allocationData) {
      state.growerAllocations = allocationData;
    },
    // SET_GROWER_CROP_ALLOCATION_RECORDS(state, allocationData) {
    //   state.growerAllocationRecords = allocationData;
    // },
    ADD_GROWER_CROP_ALLOCATION(state, allocationObject) {
      state.growerAllocations.push(allocationObject);
    },
    UPDATE_GROWER_CROP_ALLOCATION(state, allocationObject) {
      state.growerAllocations = state.growerAllocations.filter(function (obj) {
        return obj.id !== allocationObject.id;
      });
      state.growerAllocations.push(allocationObject);
    },
    DELETE_GROWER_CROP_ALLOCATION(state, allocationObject) {
      state.growerAllocations = state.growerAllocations.filter(function (obj) {
        return obj.id !== allocationObject.id;
      });
    },
    RESET_GROWER_CROP_ALLOCATIONS(state) {
      state.growerAllocations = [];
      // state.growerAllocationRecords = [];
    },
  },
  actions: {
    async getGrowerAllocations({ commit }) {
      const data = await client.grower.cropAllocations.read();
      if (data) {
        commit("SET_GROWER_CROP_ALLOCATIONS", data.allocations);
      }
    },
    // async getGrowerAllocationRecords({ commit }) {
    //   const data = await client.grower.cropAllocations.readRecords();
    //   if (data) {
    //     commit("SET_GROWER_CROP_ALLOCATION_RECORDS", data.allocation_records);
    //   }
    // },
    async createGrowerAllocation({ commit }, allocation) {
      const data = await client.grower.cropAllocations.create(null, allocation);
      if (data) {
        // console.log(data);
        commit("ADD_GROWER_CROP_ALLOCATION", data);
        this.dispatch("getGrowerAllocations");
        return data;
      }
    },
    async updateGrowerAllocation({ commit }, allocation) {
      const data = await client.grower.cropAllocations.update(
        allocation.id,
        allocation,
      );
      if (data) {
        // console.log(data);
        commit("UPDATE_GROWER_CROP_ALLOCATION", data);
        this.dispatch("getGrowerAllocations");
      }
    },
    async deleteGrowerAllocation({ commit }, allocation) {
      const data = await client.grower.cropAllocations.delete(allocation.id);
      if (data) {
        // console.log(data);
        commit("DELETE_GROWER_CROP_ALLOCATION", allocation);
        this.dispatch("getGrowerAllocations");
      }
    },
  },
  getters: {
    getAllGrowerAllocations: (state) => {
      return state.growerAllocations;
    },
    // getAllGrowerAllocationRecords: (state) => {
    //   return state.growerAllocationRecords;
    // },
    getGrowerAllocationById: (state) => (allocationId) => {
      return state.growerAllocations.find(
        (allocation) => allocation.id === parseInt(allocationId),
      );
    },
    getGrowerAllocationsByOrderId: (state) => (orderId) => {
      return state.growerAllocations.filter(
        (allocation) => allocation.crop_order === parseInt(orderId),
      );
    },
    getGrowerAllocationsByCropId: (state) => (cropId) => {
      return state.growerAllocations.filter(
        (allocation) => allocation.crop === parseInt(cropId),
      );
    },
    getSumAllocationAcresByOrderId: (state) => (orderId) => {
      let orderAllocations = state.growerAllocations.filter(
        (allocation) => allocation.crop_order === parseInt(orderId),
      );
      let sum = orderAllocations.reduce((accumulator, object) => {
        return accumulator + object.acres;
      }, 0);
      return sum;
    },
    getSumAllocationAcresByCropId: (state) => (cropId) => {
      let cropAllocations = state.growerAllocations.filter(
        (allocation) => allocation.crop === parseInt(cropId),
      );
      let sum = cropAllocations.reduce((accumulator, object) => {
        return accumulator + object.acres;
      }, 0);
      return sum;
    },
    getGrowerAllocations:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let growerAllocations = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          state.growerAllocations,
        );
        return growerAllocations;
      },
    getExpandedGrowerAllocations:
      (state) =>
      ({
        filterFunction = null,
        filter = {},
        match = "",
        sort = [],
        pageSize = null,
        pageNum = 0,
      }) => {
        let growerAllocations = state.growerAllocations;
        let expandedAllocations = growerAllocations;
        let finalAllocations = universalGetter(
          {
            filterFunction: filterFunction,
            filter: filter,
            match: match,
            sort: sort,
            pageSize: pageSize,
            pageNum: pageNum,
          },
          expandedAllocations,
        );
        return finalAllocations;
      },
    // getExpandedGrowerAllocations:
    //   (state) =>
    //   ({
    //     filterFunction = null,
    //     filter = {},
    //     match = "",
    //     sort = [],
    //     pageSize = null,
    //     pageNum = 0,
    //   }) => {
    //     let expandedGrowerAllocations = state.growerAllocations;
    //     let functionFilterGrowerAllocations = filterFunction
    //       ? _.filter(expandedGrowerAllocations, filterFunction)
    //       : expandedGrowerAllocations;
    //     let filteredGrowerAllocations = _.isEmpty(filter)
    //       ? functionFilterGrowerAllocations
    //       : _.filter(functionFilterGrowerAllocations, filter);
    //     let matchedGrowerAllocations = _.isEmpty(match)
    //       ? filteredGrowerAllocations
    //       : _.filter(filteredGrowerAllocations, (item) =>
    //           _.some(item, (val) =>
    //             _.includes(_.lowerCase(val), _.lowerCase(match))
    //           )
    //         );
    //     let sortColumns = Object.keys(sort);
    //     let sortOrders = Object.values(sort);
    //     //console.log("sort in index",sort,sortColumns, sortOrders);
    //     let sortedGrowerAllocations = _.isEmpty(sort)
    //       ? matchedGrowerAllocations
    //       : _.orderBy(matchedGrowerAllocations, sortColumns, sortOrders);
    //     let paginatedGrowerAllocations =
    //       _.isNumber(pageSize) && _.isNumber(pageNum)
    //         ? _.slice(
    //             sortedGrowerAllocations,
    //             pageSize * pageNum, // skip
    //             pageSize * pageNum + pageSize // limit
    //           )
    //         : sortedGrowerAllocations;
    //     return paginatedGrowerAllocations;
    //   },
  },
};

<template>
  <div id="printThis">
    <div class="mt-2 sm:col-span-6">
      <view-offer-terms :offerId="offerId" :orderId="orderId" />
    </div>
  </div>
  <div class="flex justify-end sm:col-span-6 print:hidden">
    <!-- <base-button
      buttonText="Print"
      :redButton="true"
      @buttonClick="printAgreement"
    /> -->
    <base-button
      buttonText="Print"
      :redButton="false"
      @buttonClick="printModal"
    />
  </div>
</template>
<script>
import ViewOfferTerms from "@/layouts/buyer-grower/crop-inquiries/ViewOfferTerms.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: {
    ViewOfferTerms,
    BaseButton,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: false,
    },
  },
  computed: {
    offerId() {
      if (!this.offer) return parseInt(this.$route.params.offerId);
      return this.offer.id;
    },
    orderId() {
      if (!this.order) return parseInt(this.$route.params.orderId);
      return this.order.id;
    },
  },
  methods: {
    printModal() {
      let elem = document.getElementById("printThis");
      var domClone = elem.cloneNode(true);
      var w = window.open();
      let tailwind =
        "<head> <link href='https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css' rel='stylesheet'></head>";

      w.document.write(
        "<title>Grower Enrollment Agreement</title><html>" +
          tailwind +
          "<body>" +
          domClone.innerHTML +
          "</body></html>",
      );
      setTimeout(() => {
        w.print();
        setTimeout(() => {
          w.close();
        }, 100);
      }, 100);

      // this approach will affect all other pages due to the necessary css. Open separate window instead.
      // let printSection = document.getElementById("printSection");
      // console.log("print section", printSection);
      // if (!printSection) {
      //   printSection = document.createElement("div");
      //   printSection.id = "printSection";
      //   document.body.appendChild(printSection);
      // }

      // printSection.innerHTML = "";
      // printSection.appendChild(domClone);
      // console.log("print section", printSection);
      // window.print();
      // printSection.remove();
    },
  },
};
</script>
<!-- <style type="text/css" media="print">
@media print {
  body * {
    display: none !important;
  }
  #printSection,
  #printThis,
  #printThis * {
    display: revert !important;
  }
}
</style> -->

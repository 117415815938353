<template>
  <template v-if="routeParam != null && currentEquipment == null">
    <page-card headingTitle="Equipment could not be found."> </page-card>
  </template>
  <template v-else>
    <delete-modal
      :open="modalIsActive"
      modalTitle="Delete Equipment"
      modalDescription="Are you sure you want to permanently delete this equipment?"
      @clickCancel="cancelDelete"
      @clickConfirmDelete="deleteEquipment"
    />
    <page-card
      :headingTitle="this.routeParam ? currentEquipment.alias : 'New Equipment'"
    >
      <template #buttonArea>
        <base-button
          buttonText="Save"
          :marginLeft="false"
          @buttonClick="submitEquipment"
        />
        <base-button
          v-if="this.routeParam"
          buttonText="Delete"
          :redButton="true"
          @buttonClick="intializeDeleteModal"
          dataTestid="delete-equipment-button"
        />
      </template>
      <template #cardDetails>
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Name"
          inputHtmlType="text"
          v-model="currentEquipment.alias"
        /><select-dropdown
          :wideItem="true"
          inputTitle="Equipment Class"
          :selectOptions="equipmentClasses"
          v-model="currentEquipment.equipment_class"
          :noSort="true"
          @change="clearDetails"
        />
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Make"
          inputHtmlType="text"
          v-model="currentEquipment.equipment_make"
        />
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Model"
          inputHtmlType="text"
          v-model="currentEquipment.equipment_model"
        />
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Serial Number"
          inputHtmlType="text"
          v-model="currentEquipment.serial_number"
        />
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Engine Number"
          inputHtmlType="text"
          v-model="currentEquipment.engine_number"
        />
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Asset Number"
          inputHtmlType="text"
          v-model="currentEquipment.asset_number"
        />
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Billing Category"
          inputHtmlType="text"
          v-model="currentEquipment.billing_category"
        />
        <variable-string-type-input
          :wideItem="true"
          inputTitle="External / Other ID"
          inputHtmlType="text"
          v-model="currentEquipment.external_id"
        />
        <number-input
          :wideItem="true"
          inputTitle="Hourly Rate"
          inputHtmlType="text"
          v-model="currentEquipment.hourly_rate"
        />
        <div class="sm:col-span-3">
          <date-input
            :wideItem="true"
            inputTitle="Purchase Date"
            inputHtmlType="text"
            v-model="purchaseDate"
          />
        </div>
        <number-input
          :wideItem="true"
          inputTitle="Purchase Cost"
          inputHtmlType="text"
          v-model="currentEquipment.cost"
        />
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Maintenance Supervisor"
          inputHtmlType="text"
          v-model="currentEquipment.supervisor"
        />
        <variable-string-type-input
          :wideItem="true"
          inputTitle="Home Location"
          inputHtmlType="text"
          v-model="currentEquipment.home_location"
        />
        <div class="sm:col-span-3">
          <date-input inputTitle="Last Serviced" v-model="lastServiceDate" />
        </div>
      </template>
      <template #componentSection>
        <TabGroup as="div">
          <div class="border-b border-gray-200">
            <TabList class="-mb-px flex space-x-8">
              <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  Map
                </button>
              </Tab>
              <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  Records
                </button>
              </Tab>
              <!-- <Tab as="template" v-slot="{ selected }">
                <button
                  :class="[
                    selected
                      ? 'border-indigo-600 text-indigo-600'
                      : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                    'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                  ]"
                >
                  Tasks
                </button>
              </Tab> -->
            </TabList>
          </div>
          <TabPanels as="template">
            <TabPanel as="dl" class="pt-10">
              <equipment-map
                v-if="equipmentLocation"
                :geojson="equipmentLocation"
              />
              <p v-else>No Location Provided</p>
            </TabPanel>
            <TabPanel class="pt-10" :unmount="false">
              <records-table
                :currentEquipmentId="parseInt(equipmentId)"
                ref="recordsTable"
              />
            </TabPanel>
            <!-- <TabPanel class="pt-10">
              <activities-table
                :currentEquipmentId="parseInt($route.params.equipmentId)"
              />
            </TabPanel> -->
          </TabPanels>
        </TabGroup>
      </template>
    </page-card>
  </template>
</template>

<script>
// import { reactive } from "vue";
// import { useRoute } from "vue-router";
// import { useStore } from "vuex";

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import VariableStringTypeInput from "@/components/form/VariableStringTypeInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import EquipmentMap from "@/layouts/grower-portal/assets/equipment/EquipmentMap.vue";
// import ActivitiesTable from "@/layouts/grower-portal/operations/ActivitiesTable.vue";
import RecordsTable from "@/layouts/grower-portal/records/RecordsTable.vue";

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    BaseButton,
    VariableStringTypeInput,
    DateInput,
    NumberInput,
    SelectDropdown,
    DeleteModal,
    EquipmentMap,
    // ActivitiesTable,
    RecordsTable,
  },
  data() {
    // const store = useStore();
    let routeParam = this.$route.params.equipmentId || null;
    let currentEquipment = routeParam
      ? null
      : {
          type: "Feature",
          geometry: {}, // object
          properties: {
            alias: null, // string
            equipment_class: null, // string
            equipment_model: null, // int
            serial_number: null, //string
            engine_number: null, // string
            asset_number: null, // string
            billing_category: null, //string
            external_id: null, //string
            hourly_rate: null, // number
            cost: null, // number
            home_location: null, //string
            supervisor: null, // string
            last_service_date: null, // date
            purchase_date: null, //date
            current_status: null, // string
            location: {}, // object
            details: {}, // object specific attributes
          },
        };
    return {
      routeParam,
      currentEquipment,
      lastServiceDate: currentEquipment?.last_service_date?.substring(0, 10),
      purchaseDate: currentEquipment?.purchase_date?.substring(0, 10),
      listOfEquipmentClasses: ["Tractor", "Attachment", "Sensor", "Other"],
      modalIsActive: false,
    };
  },
  computed: {
    existingEquipment() {
      return this.$store.getters.getEquipmentById(this.equipmentId);
    },
    equipmentId() {
      return this.$route.params.equipmentId;
    },
    equipmentClasses() {
      let options = [];
      for (let elem of this.listOfEquipmentClasses) {
        options.push(elem);
      }
      return options;
    },
    equipmentLocation() {
      if (this.currentEquipment.location) {
        let geoJson = {
          features: [
            {
              type: "Feature",
              geometry: this.currentEquipment.location,
              properties: { name: this.currentEquipment.alias },
            },
          ],
        };
        // console.log("location", geoJson);
        return geoJson;
      } else return null;
    },
    equipmentRecords() {
      // console.log("records for ", this.currentEquipment?.id);
      return this.$store.getters.getRecordsByEquipment(
        this.currentEquipment?.id,
      );
    },
    equipmentTasks() {
      return this.$store.getters.getTasksByEquipment(this.currentEquipment?.id);
    },
  },
  methods: {
    async submitEquipment() {
      if (this.lastServiceDate)
        this.currentEquipment.last_service_date = new Date(
          this.lastServiceDate,
        ).toISOString();
      if (this.purchaseDate)
        this.currentEquipment.purchase_date = new Date(this.purchaseDate)
          .toISOString()
          .substring(0, 10);
      // console.log("SAVE", this.currentEquipment);
      await this.$store.dispatch(
        this.routeParam ? "updateEquipment" : "createEquipment",
        this.currentEquipment,
      );
      this.$refs.recordsTable.saveRecords();
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "equipment" });
      }
    },
    clearDetails() {
      if (this.currentEquipment.equipment_class == "Tractor")
        this.updateEquipmentDetails({});
    },
    intializeDeleteModal() {
      this.modalIsActive = true;
    },
    async deleteEquipment() {
      this.modalIsActive = false;
      // console.log("DELETE");
      // first delete any associated records and tasks
      if (this.equipmentRecords.length > 0) {
        this.equipmentRecords.forEach(async (record) => {
          await this.$store.dispatch("deleteRecord", {
            id: record.id,
          });
        });
      }
      if (this.equipmentTasks.length > 0) {
        this.equipmentTasks.forEach(async (task) => {
          await this.$store.dispatch("deleteTask", {
            id: task.id,
          });
        });
      }

      await this.$store.dispatch("deleteEquipment", this.currentEquipment);
      if (!this.$store.getters.getApiError) {
        this.$router.push({ name: "equipment" });
      }
    },
    cancelDelete() {
      this.modalIsActive = false;
    },
    updateEquipmentDetails(e) {
      this.currentEquipment.properties.details = e;
    },
    updateEquipmentShape(feature, acres) {
      // console.log(acres)
      this.currentEquipment.properties.acres = acres;
      this.currentEquipment.geometry = feature.geometry;
    },
  },
  watch: {
    existingEquipment: {
      handler() {
        this.currentEquipment = this.existingEquipment;
        this.lastServiceDate =
          this.currentEquipment?.last_service_date?.substring(0, 10);
        this.purchaseDate = this.currentEquipment?.purchase_date?.substring(
          0,
          10,
        );
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

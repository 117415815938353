<template>
  <div class="flex justify-end">
    <base-button
      buttonText="Back"
      :redButton="true"
      @buttonClick="goToParentPage"
    />
  </div>
  <TabGroup as="div">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Import a shapefile of fields
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Import a CSV of field attributes
          </button>
        </Tab>
        <feature-flagged requires="trimble-integration">
          <Tab as="template" v-slot="{ selected }">
            <button
              :class="[
                selected
                  ? 'border-indigo-600 text-indigo-600'
                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
              ]"
            >
              Import from Trimble
            </button>
          </Tab>
        </feature-flagged>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel as="dl" class="pt-10">
        <shapefile-import :fieldAttributes="fieldAttributes" />
      </TabPanel>
      <TabPanel class="pt-10">
        <csv-import :fieldAttributes="csvFieldAttributes" />
      </TabPanel>
      <feature-flagged requires="trimble-integration">
        <TabPanel class="pt-10">
          <trimble-import />
        </TabPanel>
      </feature-flagged>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import ShapefileImport from "@/layouts/grower-portal/assets/fields/ShapefileImport.vue";
import CsvImport from "@/layouts/grower-portal/assets/fields/CsvImport.vue";
import TrimbleImport from "@/layouts/grower-portal/assets/fields/TrimbleImport.vue";
import FeatureFlagged from "@/components/containers/FeatureFlagged.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
export default {
  components: {
    ShapefileImport,
    CsvImport,
    TrimbleImport,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    FeatureFlagged,
    BaseButton,
  },
  props: {},
  data() {
    let fieldAttributes = {
      name: { required: true, label: "Field Name" },
      farm: { required: false, label: "Farm" },
      crop: { required: false, label: "Crop" },
      acres: { required: false, label: "Acres" },
      variety: { required: false, label: "Variety" },
      clone: { required: false, label: "Clone" },
      rootstock: { required: false, label: "Rootstock" },
      rowspacing: { required: false, label: "Row Spacing" },
      plantspacing: { required: false, label: "Plant Spacing" },
      planted: { required: false, label: "Planted" },
      grafted: { required: false, label: "Grafted" },
      rows: { required: false, label: "Rows" },
      tpa: { required: false, label: "TPA" },
      irrigated: { required: false, label: "Irrigated" },
      planting_status: { required: false, label: "Planting Status" },
      fsa_tract: { required: false, label: "FSA Tract" },
      fsa_field: { required: false, label: "FSA Field" },
    };

    return {
      csv: null,
      importReport: "",
      showSlideOut: false,
      fieldAttributes: fieldAttributes,
    };
  },
  computed: {
    csvFieldAttributes() {
      let attributes = this.fieldAttributes;
      attributes["wkt"] = { required: false, label: "Geometry (WKT format)" };
      return attributes;
    },
  },
  methods: {
    goToParentPage() {
      let parentRoute = this.$store.getters.getParentRoute;
      let parentId = this.$store.getters.getParentId;
      if (parentRoute == "grower-portal-offer-order") {
        this.$router.push({
          name: "grower-portal-offer-order",
          params: { orderId: parentId },
        });
      } else {
        this.$router.push({ name: "fields" });
      }
    },
  },
  setup() {},
  async created() {},
  mounted() {},
};
</script>

import Papa from "papaparse";
import shpwrite from "@mapbox/shp-write";

export function exportToCSV(data, filename) {
  if (!filename) filename = "export.csv";
  let options = {
    quotes: false, //or array of booleans
    quoteChar: '"',
    escapeChar: '"',
    delimiter: ",",
    header: true,
    newline: "\r\n",
    skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
    columns: null, //or array of strings
  };
  const csv = Papa.unparse(data, options);
  // console.log(csv);
  const blob = new Blob([csv]);
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob, { type: "text/plain" });
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
function formatGeoJSON(data) {
  let geoJSON = {
    type: "FeatureCollection",
    features: [],
  };
  data.forEach((item) => {
    console.log(item);
    let properties = {};
    Object.keys(item.properties).forEach((key) => {
      if (key !== "details" && key !== "current_crop") {
        properties[key] = item.properties[key];
      }
    });
    if (item.properties.current_crop) {
      Object.keys(item.properties?.current_crop)?.forEach((key) => {
        if (key !== "details") {
          properties[key] = item.properties.current_crop[key];
        }
      });
      Object.keys(item.properties?.current_crop?.details)?.forEach((key) => {
        properties[key] = item.properties.current_crop.details[key];
      });
    }
    geoJSON.features.push({
      type: "Feature",
      geometry: item.geometry,
      properties: properties,
    });
  });
  // console.log(geoJSON);

  return geoJSON;
}
export function exportToGeoJSON(data, filename) {
  if (!filename) filename = "export.json";
  let geoJSON = formatGeoJSON(data);
  const blob = new Blob([JSON.stringify(geoJSON)]);
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob, { type: "text/plain" });
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export async function exportToShapefile(data, filename) {
  if (!filename) filename = "export.zip";
  let fileContent = filename.split(".")[0];

  // Convert Polygon to MultiPolygon for shp-write
  for (let feature of data) {
    if (!feature.geometry) {
      continue;
    }
    if (feature.geometry.type === "Polygon") {
      feature.geometry.type = "MultiPolygon";
      feature.geometry.coordinates = [feature.geometry.coordinates];
    }
  }
  data = data.filter((feature) => feature.geometry); // Remove empty geometry fields
  let geoJSON = formatGeoJSON(data);

  const options = {
    filename: filename,
    outputType: "blob",
    compression: "DEFLATE",
    types: {
      polygon: fileContent,
      // point: "mypoints",
      // polyline: "mylines",
    },
  };

  let zipData = await shpwrite.zip(geoJSON, options);
  // console.log("zipData", zipData, data, geoJSON);

  const blob = new Blob([zipData]);

  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob, { type: "application/zip" });
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

<template>
  <modal
    :show="showRecordModal"
    dialogPanelClass="max-w-screen-lg"
    :title="null"
  >
    <record-form
      @closeRecord="showRecordModal = false"
      :recordProp="activeRecord"
      :displayOnly="true"
      :recordType="activeRecord.category"
    />
  </modal>
  <slot-modal
    :open="showAgreementModal"
    modalTitle="Grower Agreement"
    modalDescription=""
    @closeModal="this.showAgreementModal = false"
  >
    <!-- <template #icon
      ><PaperAirplaneIcon class="text-black-600 h-6 w-6" aria-hidden="true"
    /></template> -->
    <template #content>
      <view-crop-order-agreement :offer="currentOffer" :order="currentOrder" />
    </template>
  </slot-modal>
  <!-- <page-card :headingTitle="'Order: ' + $route.params.orderId"> -->
  <page-card
    :headingTitle="
      currentOrder?.quantity +
      ' ' +
      currentOffer?.quantity_units +
      ' of ' +
      currentOrder?.details?.crop +
      ' from ' +
      currentOrder?.seller_details?.name
    "
    :headingDescription="
      'Order # ' + $route.params.orderId + ' | ' + currentStatus
    "
  >
    <template #buttonArea>
      <div class="flex">
        <base-button
          buttonText="View Agreement"
          :marginLeft="true"
          @buttonClick="showAgreementModal = true"
        />
        <!-- @buttonClick="
            $router.push({
              name: 'buyer-view-crop-order-agreement',
              params: { offerId: currentOrder.offer, orderId: currentOrder.id },
            })
          " -->
        <div v-if="isExternalOrg">
          <base-button
            buttonText="Impersonate"
            :marginLeft="true"
            @buttonClick="impersonateGrower()"
          />
        </div>

        <base-button
          buttonText="Save"
          :marginLeft="true"
          @buttonClick="
            saveOrder();
            saveAttachments();
          "
        />
      </div>
    </template>
    <template #cardDetails>
      <crop-order-display
        :orderId="parseInt($route.params.orderId)"
        @update:Order="updateOrder"
      />
      <crop-allocation-aggregate
        v-if="currentOrder && currentAllocations"
        :allocations="currentAllocations"
        :currentOrderUnits="currentOrder.units"
      />
      <crop-harvest-aggregate :orderId="orderId" perspective="buyer" />
      <crop-delivery-aggregate :orderId="orderId" perspective="buyer" />
    </template>
    <template #componentSection>
      <!-- Tabs Below Grid -->
      <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Fields
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premium
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Records
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }" v-if="currentOffer">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Offer Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Deliveries
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Chat
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <TabPanel as="dl" class="pt-10" v-if="currentAllocations">
            <table-card>
              <template #header>
                <table-head
                  :columns="[
                    {
                      name: 'field_details.properties.name',
                      label: 'Field',
                      filtered: false,
                    },
                    { name: 'crop_details.crop_type', label: 'Crop' },
                    { name: 'crop_details.details.variety', label: 'Variety' },
                    {
                      name: 'acres',
                      label: 'Allocated Acres',
                      filtered: false,
                    },
                    {
                      name: '',
                      label: 'Estimated Yield',
                      filtered: false,
                      sorted: false,
                    },
                  ]"
                  :collection="currentAllocations"
                  @update:derived-collection="derivedAllocations = $event"
                >
                </table-head>
              </template>
              <tbody>
                <tr
                  v-for="allocation in derivedAllocations"
                  :key="allocation.id"
                >
                  <table-data td-label="Field">
                    <div class="flex">
                      <field-thumbnail
                        :title="
                          allocation.shared_data.field_details.properties.name
                        "
                        :geojson="allocation.shared_data.field_details"
                        class="mr-6"
                      ></field-thumbnail>
                      {{ allocation.shared_data.field_details.properties.name }}
                    </div>
                  </table-data>
                  <table-data td-label="Crop">
                    {{ allocation.shared_data.crop_details.crop_type }}
                  </table-data>
                  <table-data td-label="Variety">
                    {{ allocation.shared_data.crop_details.details.variety }}
                  </table-data>
                  <table-data td-label="Allocated Acres">
                    {{ allocation.acres }}
                  </table-data>
                  <table-data td-label="Estimated Yield">
                    {{
                      allocation.shared_data.crop_details.details.tpa
                        ? allocation.acres *
                            allocation.shared_data.crop_details.details.tpa +
                          " Tons"
                        : "N/A"
                    }}
                  </table-data>
                </tr>
              </tbody>
            </table-card>
          </TabPanel>
          <TabPanel as="dl" v-if="currentOffer">
            <!-- <crop-premium-display :premiums="currentOffer?.premiums" /> -->

            <div
              class="col-span-6 h-full rounded-lg bg-gray-50 shadow-sm ring-1 ring-gray-900/5"
            >
              <div v-if="currentPremium">
                <premium-requirements :currentPremium="currentPremium" />
              </div>
              <div
                v-if="currentPremium"
                class="my-2 ml-2 text-left text-sm font-medium leading-6 text-gray-900 sm:col-span-6"
              >
                {{ currentPremium.terms }} pays
                {{ summarizePremium(currentPremium) }}
              </div>
              <table
                class="text-md my-2 ml-2 text-left font-medium text-gray-500 sm:col-span-6"
              >
                <tr class="font-bold text-gray-700">
                  <th>Field</th>
                  <th>Status</th>
                </tr>
                <template
                  v-for="allocation in currentAllocations"
                  :key="allocation.id"
                >
                  <tr class="text-sm leading-6 text-gray-900">
                    <td>
                      {{ allocation.shared_data.field_details.properties.name }}
                    </td>
                    <td>
                      {{
                        allocation.validations_met?.length > 0
                          ? "Grower has marked all requirements met"
                          : "Still gathering records"
                      }}
                    </td>
                  </tr></template
                >
              </table>
            </div>

            <field-validation-table
              v-if="
                currentPremium && currentPremium.validation_records?.length > 0
              "
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-4" v-if="currentRecords">
            <div class="text-right" v-if="isExternalOrg">
              <div class="text-xs text-gray-500">
                Click here to edit records as the grower. You can download the
                spreadsheet from here as well. &nbsp; &nbsp;
                <base-button
                  buttonText="Edit Records"
                  :marginLeft="false"
                  @buttonClick="
                    impersonateTab = 1;
                    impersonateGrower();
                  "
                />
              </div>
            </div>
            <table-card>
              <template #header>
                <table-head
                  :columns="[
                    {
                      name: 'field_details.properties.name',
                      label: 'Field',
                    },
                    { name: 'crop_details.crop_type', label: 'Crop' },
                    {
                      name: 'category',
                      label: 'Category',
                    },
                    { name: 'result', label: 'Result' },
                    { name: 'year', label: 'Year' },
                    { name: '', label: '', filtered: false, sorted: false },
                  ]"
                  :collection="currentRecords"
                  @update:derived-collection="derivedRecords = $event"
                >
                </table-head>
              </template>
              <tbody>
                <tr v-for="(record, idx) in derivedRecords" :key="record">
                  <table-data td-label="Field">
                    <div class="flex">
                      <field-thumbnail
                        :title="record.field_details.properties.name"
                        :geojson="record.field_details"
                        class="mr-6"
                      ></field-thumbnail>
                      {{ record.field_details.properties.name }}
                    </div>
                  </table-data>
                  <table-data td-label="Crop">
                    {{ record.crop_details.crop_type }}
                  </table-data>
                  <table-data td-label="Category">
                    {{ record.category }}
                  </table-data>
                  <table-data td-label="Result">
                    {{ record.result }}
                  </table-data>
                  <table-data td-label="Completed At">
                    {{
                      record.completed_at
                        ? new Date(record.completed_at)
                            .toISOString()
                            .substring(0, 10)
                        : ""
                    }}
                  </table-data>
                  <table-data class="text-center">
                    <button
                      @click="showRecord(idx)"
                      :disabled="false"
                      v-if="record.category != 'Note'"
                    >
                      View
                    </button>
                  </table-data>
                </tr>
              </tbody>
            </table-card>
          </TabPanel>
          <TabPanel as="dl" class="pt-10" v-if="currentOffer">
            <offer-details :offerId="currentOffer.id" />
          </TabPanel>
          <TabPanel as="dl" class="pt-10" :unmount="false" v-if="currentOrder">
            <!-- <view-attachments-table
              v-if="currentOrder?.status != 'Internal'"
              :attachments="[]"
            />
            v-else 
            -->
            <add-attachments-table
              :parentId="currentOrder?.id"
              :attachments="currentOrder?.attachments"
              addStoreAction="uploadBuyerOrderAttachment"
              removeStoreAction="removeBuyerOrderAttachment"
              ref="attachmentsTable"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10" v-if="currentOrderDeliveries">
            <crop-delivery-table
              :deliveries="currentOrderDeliveries"
            ></crop-delivery-table>
          </TabPanel>
          <TabPanel as="dl" class="pt-10" v-if="currentOrder">
            <chat-single-view :thread-id="currentOrder?.details?.threadId" />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import CropOrderDisplay from "@/layouts/buyer-grower/crop-orders/CropOrderDisplay.vue";
import ViewCropOrderAgreement from "@/pages/buyer-portal/bp-affiliate/orders/ViewCropOrderAgreement.vue";
// import CropPremiumDisplay from "@/layouts/buyer-grower/crop-orders/CropPremiumDisplay.vue";
import PremiumRequirements from "@/layouts/grower-portal/sell/orders/PremiumRequirements.vue";

import FieldValidationTable from "@/pages/buyer-portal/bp-affiliate/orders/FieldValidationTable.vue";
import { summarizePremium } from "@/components/composables/scriptUtils";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";
// import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
import CropAllocationAggregate from "@/layouts/buyer-portal/common/orders/CropAllocationAggregate.vue";
import CropDeliveryAggregate from "@/layouts/buyer-portal/common/orders/CropDeliveryAggregate.vue";
import CropHarvestAggregate from "@/layouts/common/orders/CropHarvestAggregate.vue";
import CropDeliveryTable from "@/layouts/buyer-portal/bp-affiliate/orders/CropDeliveryTable.vue";
import OfferDetails from "@/layouts/buyer-portal/bp-affiliate/offers/OfferDetails.vue";

import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

import ChatSingleView from "@/layouts/common/ChatSingleView.vue";

import PageCard from "@/components/cards/PageCard.vue";

import RecordForm from "@/layouts/grower-portal/records/RecordForm.vue";
import Modal from "@/components/modals/PopupModal.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import { useToast } from "vue-toastification";
// import TextInput from "@/components/form/TextInput.vue";
// import NumberInput from "@/components/form/NumberInput.vue";

const RecordTypeDefintions = require("@/layouts/grower-portal/records/RecordTypes.json");

export default {
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    PageCard,
    CropOrderDisplay,
    ViewCropOrderAgreement,
    // CropPremiumDisplay,
    PremiumRequirements,

    FieldValidationTable,
    AddAttachmentsTable,
    // ViewAttachmentsTable,
    TableCard,
    TableHead,
    TableData,
    BaseButton,
    FieldThumbnail,
    CropAllocationAggregate,
    CropDeliveryAggregate,
    CropHarvestAggregate,
    OfferDetails,
    ChatSingleView,
    RecordForm,
    Modal,
    SlotModal,
    CropDeliveryTable,
    // TextInput,
    // NumberInput,
  },
  data() {
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return {
      derivedAllocations: [],
      derivedRecords: [],
      showRecordModal: false,
      showAgreementModal: false,
      activeRecord: null,
      updatedOrder: null,
      selectedTab: selectedTab,
      impersonateTab: 0,
      toast: useToast(),
    };
  },
  computed: {
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    currentOrder() {
      if (!this.orderId) return null;
      return this.$store.getters.getBuyerOrderById(this.orderId);
    },
    currentAllocations() {
      if (!this.orderId) return null;
      return this.$store.getters.getBuyerAllocationsByOrder(this.orderId);
    },
    currentRecords() {
      if (!this.currentAllocations) return null;
      // iterate through currentAllocations and get records from each in an array at record.shared_data.record_details
      let records = [];
      this.currentAllocations.forEach((allocation) => {
        this.$store.getters
          .getBuyerAllocationRecordsByIds(allocation.id, this.orderId)
          .forEach((record) => {
            records.push(
              Object.assign({}, record, {
                allocation: allocation.id,
                field_details: allocation.shared_data.field_details,
                crop_details: allocation.shared_data.crop_details,
              }),
            );
          });
      });
      return records;
    },
    recordTypes() {
      let recordTypes = RecordTypeDefintions.filter(
        (recordType) => !recordType.readonly,
      );
      recordTypes = recordTypes.map((recordType) => {
        return {
          label: recordType.category,
          event: recordType.category,
        };
      });
      return recordTypes;
    },
    currentOffer() {
      if (!this.currentOrder) return null;
      return this.$store.getters.getBuyerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    currentPremium() {
      if (!this.currentOrder || !this.currentOffer) return null;
      let premium = this.currentOffer?.premiums.find(
        (premium) => premium.id == this.currentOrder.premium,
      );
      return premium;
    },
    currentStatus() {
      let status = "";
      if (this.currentOrder?.grower_signature) {
        status += "Agreed and ";
      } else if (
        this.currentOrder?.buyer_signature == false &&
        this.currentOrder?.status == "Inquiry"
      ) {
        status += "Pending ";
      }
      status += this.currentOrder?.status;
      if (status == "Internal") {
        return "Crop Order";
      }
      return status;
    },
    currentOrderDeliveries() {
      if (!this.orderId) return null;
      let deliveries = this.$store.state.buyerDeliveries.buyerDeliveries.filter(
        (d) => d.crop_order === parseInt(this.orderId),
      );
      return deliveries;
    },
    isExternalOrg() {
      let orgId = this.currentOrder?.seller_details?.id;
      let externalOrg = this.$store.getters.getSharedOrgById(orgId);
      // console.log("allowsExternalOrg", externalOrg, orgId);
      if (externalOrg) {
        return true;
      }
      return false;
    },
    // currentOfferCropOrdersSummary() {
    //   let cropOrders = this.$store.getters.getBuyerCropOrdersByMarketOffer(
    //     this.currentOrder.offer,
    //   );
    //   let summary = {
    //     quantity: this.currentOffer.quantity,
    //     units: this.currentOffer.quantity_units,
    //     // currentAllocation is the sum of each order's allocated_acres attributes
    //     currentAllocation: cropOrders.reduce(
    //       (acc, order) => acc + order.allocated_acres,
    //       0,
    //     ),
    //   };
    //   return summary;
    // },
  },
  methods: {
    showRecord(id) {
      this.activeRecord = this.derivedRecords[id];
      this.displayOnly = true;
      this.showRecordModal = true;
    },
    updateOrder(order) {
      this.updatedOrder = order;
    },
    saveOrder() {
      let order = this.currentOrder;
      if (this.updatedOrder) {
        order.buyer_notes = this.updatedOrder.buyer_notes;
        order.quantity = this.updatedOrder.quantity;
      }
      this.$store.dispatch("updateBuyerCropOrderNotes", order);
      this.toast.success("Changes Saved", {
        timeout: 1500,
        hideProgressBar: true,
      });
    },
    saveAttachments() {
      // console.log("saveAttachments", this.$refs, this.$refs.attachmentsTable);
      this.$refs.attachmentsTable.saveAttachments();
    },
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      this.$router.push({
        query: { tab: index },
      });
    },

    async impersonateGrower() {
      if (!this.currentOrder) return;
      let routeName = "grower-portal-produce-order";
      if (this.currentOrder.offer) {
        routeName = "grower-portal-offer-order";
      }
      await this.$store.dispatch("loginSwitchContext", {
        shared_org: this.currentOrder.seller_details.id,
      });
      this.$router.push({
        name: routeName,
        params: { orderId: this.orderId },
        query: { tab: this.impersonateTab },
      });
    },
    summarizePremium,
  },
};
</script>

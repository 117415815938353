<template>
  <error-notification />
  <!-- component -->
  <div class="bg-grey-lighter flex min-h-screen flex-col">
    <div
      class="container mx-auto flex max-w-3xl flex-1 flex-col items-center justify-center px-2"
    >
      <img :class="logo_class" :src="logo" alt="Parcel Logo" />
      <div class="w-full rounded bg-white px-6 py-8 text-black shadow-md">
        <h1 class="mb-8 text-center text-3xl">Create an Account</h1>
        <p class="pb-6 text-center">
          Let's get you set up
          {{ referrer ? "with " + referrer : "in Parcel" }}.&nbsp; Please fill
          out the information
        </p>
        <radio-input
          v-model="accountType"
          :selectOptions="[
            { label: 'Create a new organization', value: 'new' },
            { label: 'Join an existing organization', value: 'existing' },
          ]"
        />
        <div v-show="accountType === 'existing'">
          <p class="p-4 text-red-600">
            Thanks! You don't need to sign up here. Please ask your adminstrator
            to add you as a user, and you can sign in as soon as they do.
          </p>
        </div>
        <p class="px-6">&nbsp;</p>
        <text-input
          inputTitle="First and Last Name"
          v-model="fullname"
          placeholder="Name"
          required
        />
        <p class="px-2">&nbsp;</p>
        <text-input
          v-if="currentUser.email"
          inputTitle="Email"
          :display-only="true"
          :display-only-value="currentUser.email"
          required
          help-text="This is the email address you will log in with."
        />
        <text-input
          v-else-if="!currentUser.email && currentUser.phone"
          inputTitle="Phone"
          :display-only="true"
          :display-only-value="currentUser.phone"
          required
          help-text="This is the phone number you will log in with."
        />
        <p class="px-2">&nbsp;</p>
        <text-input
          :inputTitle="
            orgType == 'grower' ? 'Farm / Business Name' : 'Business Name'
          "
          v-model="company"
          placeholder="Farm Name"
          required
        />
        <p class="px-2">&nbsp;</p>
        <email-input
          :inputTitle="
            orgType == 'grower' ? 'Farm / Business Email' : 'Contact Email'
          "
          v-model="email"
          placeholder="example@email.com"
          @valid="emailValidate"
          required
          help-text="This is the email address we will use to contact your business for any notifications."
        />
        <p class="px-2">&nbsp;</p>
        <!-- <phone-input
          inputTitle="Company Contact Phone"
          v-model="phone"
          placeholder="+1 222 333 4444"
          @valid="phoneValidate"
        /> -->
        <div v-if="!referrer">
          <p class="py-4">My Organization Type Is:</p>
          <radio-input
            v-model="orgType"
            :selectOptions="[
              {
                label:
                  'Grower. I am interested in managing my farming operations',
                value: 'grower',
              },
              {
                label:
                  'Vendor, I am interested in selling products or services to growers',
                value: 'vendor',
              },
              {
                label:
                  'Buyer, I am interested in sourcing produce from growers',
                value: 'buyer',
              },
            ]"
          />
        </div>
        <!-- <checkbox-input
            inputTitle="Manage farming operations"
            v-model="grower"
          />
          <checkbox-input
            inputTitle="Sell products or services to growers"
            v-model="vendor"
          />
          <checkbox-input inputTitle="Buy produce from growers" v-model="buyer" /> -->

        <button
          @disabled="!submitIsCorrect"
          @click="createOrg"
          class="hover:bg-green-dark my-1 w-full rounded py-3 text-center text-white focus:outline-none"
          :class="submitIsCorrect ? 'bg-green-400' : 'bg-gray-400'"
        >
          {{
            orgType == "grower" && referrer == "GRP"
              ? "Next Step: Add Partners"
              : "Create Organization"
          }}
        </button>
        <div
          class="text-grey-dark mt-4 text-center text-sm"
          v-if="orgType === 'vendor'"
        >
          By signing up, you agree to the
          <a
            class="border-grey-dark text-grey-dark border-b no-underline"
            href="https://parcel.ag/tos"
            TARGET="_blank"
          >
            Terms of Service
          </a>
          ,
          <a
            class="border-grey-dark text-grey-dark border-b no-underline"
            href="https://parcel.ag/tos/vendor"
            TARGET="_blank"
          >
            Vendor Sales Agreement
          </a>
          , and
          <a
            class="border-grey-dark text-grey-dark border-b no-underline"
            href="https://parcel.ag/tos/privacy"
            TARGET="_blank"
          >
            Privacy Policy
          </a>
        </div>
        <div class="text-grey-dark mt-4 text-center text-sm" v-else>
          By signing up, you agree to the
          <a
            class="border-grey-dark text-grey-dark border-b no-underline"
            href="https://parcel.ag/tos"
            TARGET="_blank"
          >
            Terms of Service
          </a>
          and
          <a
            class="border-grey-dark text-grey-dark border-b no-underline"
            href="https://parcel.ag/tos/privacy"
            TARGET="_blank"
          >
            Privacy Policy
          </a>
        </div>
      </div>

      <div class="text-grey-dark mt-6">
        Logged in to the wrong account?
        <button
          class="border-blue border-b text-twilight-800 no-underline"
          @click="logout"
        >
          Log out</button
        >.
      </div>
      <div class="text-grey-dark mt-6">
        <a
          :href="homeURL"
          class="mt-6 self-center pt-4 text-sm lowercase text-twilight-600"
          >Return to homepage
        </a>
      </div>
    </div>
    <modal
      :show="showLoading"
      title="Getting things ready, setting up your account..."
    >
      <div class="pt-8">
        <div class="pl-32"><loading-spinner /></div>
      </div>
    </modal>
  </div>
</template>

<script>
// const stateList = require("@/assets/statelist.js");
// import PhoneInput from "@/components/form/PhoneInput.vue";
import EmailInput from "@/components/form/EmailInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import RadioInput from "@/components/form/RadioInput.vue";
import ErrorNotification from "@/components/notifications/ErrorNotification.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import Modal from "@/components/modals/PopupModal.vue";

export default {
  components: {
    // PhoneInput,
    EmailInput,
    TextInput,
    RadioInput,
    ErrorNotification,
    LoadingSpinner,
    Modal,
  },
  data() {
    let referrer = this.$store.state.signupReferrer.referrer;
    let homeURL = "https://parcel.ag";
    if (process.env.VUE_APP_ENV === "development") {
      homeURL = "https://dev.parcel.ag";
    }
    if (process.env.VUE_APP_ENV === "sandbox") {
      homeURL = "https://sandbox.parcel.ag";
    }

    return {
      referrer,
      accountType: "new",
      orgType: "grower",
      company: "",
      fullname: "",
      email: "",
      emailValid: true,
      phone: null,
      //   phoneValid: true,
      grower: false,
      vendor: false,
      buyer: false,
      creatingOrg: false,
      homeURL: homeURL,
      errorArray: [],
    };
  },
  computed: {
    logo() {
      let logo = require("../../assets/parcel-logo-name.svg");
      if (this.referrer) {
        let sponsor = this.$store.getters.getSponsorById(this.referrer);
        // console.log("referrer", this.$route.query.referrer, sponsor);
        if (sponsor) {
          logo = sponsor.login_logo_url;
        }
      }
      return logo;
    },
    logo_class() {
      let logo_class = "h-12 w-auto";
      if (this.$route.query.referrer) {
        let sponsor = this.$store.getters.getSponsorById(
          this.$route.query.referrer,
        );
        // console.log("referrer", this.$route.query.referrer, sponsor);
        if (sponsor) {
          logo_class = sponsor.login_logo_class;
        }
      }
      return logo_class;
    },
    submitIsCorrect() {
      if (
        this.accountType === "new" &&
        this.orgType.length > 0 &&
        this.company.length > 0 &&
        this.fullname.length > 0 &&
        this.emailValid &&
        this.email.length > 0
      )
        return true;
      return false;
    },
    showLoading() {
      if (this.creatingOrg) return true;
      return false;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    adminUser() {
      let user = this.$store.getters.getCurrentUser;
      user.name = this.fullname;
      user.admin = true;
      user.company = this.company;
      return user;
    },
  },
  methods: {
    // phoneValidate(valid) {
    //   this.phoneValid = valid;
    // },
    emailValidate(valid) {
      this.emailValid = valid;
    },
    async logout() {
      await this.$store.dispatch("logout").then(() => {
        this.$router.push({ path: "/login" });
      });
    },
    async createOrg() {
      if (!this.submitIsCorrect) {
        return;
      }
      this.creatingOrg = true;
      let loginRedirectName = "";
      let feature_flags;
      let application_flag;
      switch (this.orgType) {
        case "vendor":
          this.vendor = true;
          loginRedirectName = "vendor-portal-home";
          application_flag = "revenue";
          feature_flags = ["vendor-portal"];
          break;
        case "buyer":
          this.buyer = true;
          loginRedirectName = "buyer";
          application_flag = "purchases";
          feature_flags = ["buyer-portal"];
          break;
        case "grower":
        default:
          this.grower = true;
          feature_flags = [
            "gp-recs",
            "gp-chat",
            "gp-buy",
            "gp-hauling",
            "gp-sell",
            "gp-help",
            "gp-carbon",
          ];
          if (this.referrer != "GRP") {
            loginRedirectName = "grower-portal-home";
          } else {
            feature_flags = feature_flags.filter(function (item) {
              return item !== "gp-buy";
            });
            loginRedirectName = "assign-partners";
          }
          application_flag = "operations";
          break;
      }
      // console.log("loginRedirectName", loginRedirectName);
      let newOrg = {
        name: this.company,
        contact_phone: this.phone,
        contact_email: this.email,
        feature_flags,
        application_flag,
        referrer: this.$store.state.signupReferrer.referrer,
      };
      this.grower ? (newOrg.assets_flag = true) : (newOrg.assets_flag = false);
      this.grower
        ? (newOrg.operations_flag = true)
        : (newOrg.operations_flag = false);
      this.buyer
        ? (newOrg.purchases_flag = true)
        : (newOrg.purchases_flag = false);
      this.vendor
        ? (newOrg.revenue_flag = true)
        : (newOrg.revenue_flag = false);
      //if (this.state != "California") newOrg.purchases_flag = false; //TODO: Look up all product listings and see what states have product listings in them.
      // console.log("posting new org", newOrg);
      await this.$store.dispatch("createOrg", newOrg);
      this.$store.dispatch("unsetReferrer");

      // console.log("updating admin user", adminUser);
      await this.$store.dispatch("updateProfile", this.adminUser);

      // Refresh all the data in the app now that an org has been created
      await this.$store.dispatch("preloadAppData");
      //Now that we exist, let's send us to the correct home page.

      // this.$router.push({ name: "grower-portal-home" });
      const { redirect, params } = this.$route.query;
      if (redirect) {
        this.$router.push({
          name: redirect,
          params: params ? JSON.parse(params) : {},
        });
      } else {
        this.$router.push({ name: loginRedirectName });
      }
    },
  },
  watch: {
    currentUser: {
      handler: function (newVal) {
        if (newVal && newVal.email) {
          // console.log("newVal deep", newVal);
          this.email = newVal.email;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          {
            name: 'contract_name',
            label: 'Contract',
            hidden: offerId ? true : false,
          },
          { name: 'seller_name', label: 'Seller' },
          { name: 'status', label: 'Status' },

          { name: 'quantity', label: 'Qty Ordered', filtered: false },
          { name: 'units', label: 'Units', filtered: false },
          {
            name: 'allocated_acres',
            label: 'Allocated Acres',
            filtered: false,
          },
          { name: 'crop_type', label: 'Crop' },
          { name: 'crop_variety', label: 'Variety' },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="orders"
        @update:derived-collection="derivedOrders = $event"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="Add Order"
              :usePlusIcon="true"
              @buttonClick="addOrder"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="order in derivedOrders" :key="order.id">
        <table-data td-label="Contract" v-if="!offerId">
          {{ order?.contract_name }}
        </table-data>
        <table-data td-label="Seller">
          {{ order.seller_details?.name }}
        </table-data>
        <table-body-cell-pill
          td-label="Status"
          :hiddenOnSmallScreens="true"
          :cellData="order.status"
          :pillColorIndex="order.status === 'In Progress' ? 0 : 1"
        />
        <table-data td-label="Qty Ordered" class="text-right">
          {{
            order.quantity?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            })
          }}
        </table-data>
        <table-data td-label="Units">
          {{ order.units }}
        </table-data>
        <table-data td-label="Acres" class="text-right">
          {{
            getAllocatedAcres(order.id)?.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            })
          }}
          <!-- {{ order.allocated_acres.toFixed(1) }}  this is being returned as zero, so computing from allocations-->
        </table-data>
        <table-data td-label="Crop">
          <!-- {{ getCropsbyBuyerOrderId(order.id) }} -->
          {{ order.crop_type }}
        </table-data>
        <table-data td-label="Variety">
          <!-- {{ getVarietiesbyBuyerOrderId(order.id) }} -->
          {{ order.crop_variety }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-view-order',
              params: { orderId: order.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
import TableBodyCellPill from "@/components/table/TableBodyCellPill.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    TableHead,
    TableCard,
    TableData,
    TableBodyCellPill,
    TableToolbarButton,
  },
  props: {
    offerId: {
      type: [Number, String],
      default: null,
    },
    growerId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      derivedOrders: [],
    };
  },
  computed: {
    orders() {
      // console.log("looking up orders");
      let orders = this.$store.getters.getEnrichedBuyerOrders({
        filterFunction: (order) => {
          return (
            order.status === "In Progress" ||
            order.status === "Internal" ||
            order.status === "Completed"
          );
        },
      });
      // console.log("orders before: ", orders);
      if (this.offerId) {
        orders = orders.filter((order) => order.offer == this.offerId);
      }
      if (this.growerId) {
        orders = orders.filter(
          (order) => order.seller_details.id == this.growerId,
        );
      }
      // console.log("orders after: ", orders);
      orders = orders.map((order) => {
        // Moved to getEnrichedBuyerOrders because testing / modifying here was causing orders to recomputed recursively infinitely
        // if (
        //   (order.crop_type == "" || order.crop_type == null) &&
        //   order.details.crop
        // ) {
        //   order.crop_type = order.details.crop;
        // }

        // if (order.listing) {
        //   let listing = this.$store.getters.getBuyerListingById(order.listing);
        //   if (listing) {
        //     order.crop_type = listing.crop_type;
        //     order.crop_variety = listing.crop_details?.variety;
        //   }
        // }
        order.seller_name = order.seller_details?.name;
        order.contract_name = this.$store.getters.getBuyerMarketOfferById(
          order.offer,
        )?.contract_name;
        return order;
      });
      // console.log("orders: ", orders);
      return orders;
    },
  },
  methods: {
    getAllocatedAcres(orderId) {
      let allocations = this.$store.getters.getBuyerAllocationsByOrder(orderId);
      let acres = 0;
      allocations.forEach((allocation) => {
        acres += allocation.acres;
      });
      return acres;
    },
    addOrder() {
      // check for an offerId and growerId in the route
      console.log("offer: ", this.offerId, ", grower: ", this.growerId);
      if (this.growerId) {
        this.$router.push({
          name: "buyer-bp-affiliate-order-new",
          query: { growerId: this.growerId },
        });
      } else if (this.offerId) {
        this.$router.push({
          name: "buyer-bp-affiliate-order-new",
          query: { offerId: this.offerId },
        });
      } else {
        this.$router.push({ name: "buyer-bp-affiliate-order-new" });
      }
    },
  },
};
</script>

<style></style>

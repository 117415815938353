<template>
  <page-card headingTitle="New Contract">
    <template #buttonArea>
      <base-button
        buttonText="Back"
        :marginLeft="true"
        :disabled="currentStep <= 1"
        @buttonClick="handleStepChange(currentStep - 1)"
      />
      <base-button
        buttonText="Next"
        :marginLeft="true"
        :disabled="currentStep >= 4"
        @buttonClick="handleStepChange(currentStep + 1)"
      />
      <base-button
        buttonText="Save"
        :marginLeft="true"
        :disabled="currentStep == 4 ? false : true"
        @buttonClick="createContract"
      />
    </template>
    <template #cardDetails>
      <div class="sm:col-span-6">
        <horizontal-form-steps :steps="newContractSteps" />
      </div>
      <contract-form-details
        v-if="currentStep == 1"
        v-model="currentContractDetails"
      />
      <contract-form-premiums
        v-if="currentStep == 2"
        v-model="currentContractPremiums"
        :cropType="currentContractDetails.crop"
      />
      <contract-form-territory
        v-if="currentStep == 3"
        v-model="currentContractTerritory"
      />
      <template v-if="currentStep == 4">
        <div
          class="mt-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
        >
          Attachments
        </div>
        <div class="sm:col-span-6">
          <add-attachments-table
            :parentId="contractId"
            :attachments="currentContractAttachments"
            addStoreAction="uploadContractAttachment"
            removeStoreAction="removeContractAttachment"
            ref="attachmentsTable"
          />
        </div>
      </template>
    </template>
    <template #componentSection>
      <div class="flex justify-end sm:col-span-6">
        <base-button
          buttonText="Back"
          :marginLeft="true"
          :disabled="currentStep <= 1"
          @buttonClick="handleStepChange(currentStep - 1)"
        />
        <base-button
          buttonText="Next"
          :marginLeft="true"
          :disabled="currentStep >= 4"
          @buttonClick="handleStepChange(currentStep + 1)"
        />
        <!-- <base-button
          buttonText="Save"
          :marginLeft="true"
          :disabled="currentStep == 4 ? false : true"
          @buttonClick="createContract"
        /> -->
      </div>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";
import ContractFormPremiums from "@/layouts/common/contracts/ContractFormPremiums.vue";
import ContractFormTerritory from "@/layouts/common/contracts/ContractFormTerritory.vue";
import AddAttachmentsTable from "@/layouts/common/attachments/AddAttachmentsTable.vue";

import HorizontalFormSteps from "@/components/progress/HorizontalFormSteps.vue";
import { useToast } from "vue-toastification";

// import zod, use it to check if the required data is present
import { z } from "zod";

// define the contract schema, specifically the required attributes
// crop, quantity, quantity_units, contract_name, deliver_by, delivery_location
// are all required, and empty strings are not allowed. nonempty() has been deprecated, so use Use z.string().min(1) instead.
const contractSchema = z.object({
  crop: z.string().min(1),
  quantity: z.number().positive(),
  quantity_units: z.string().min(1),
  contract_name: z.string().min(1),
  deliver_by: z.string().min(1),
  additional_prior_year_records: z.number().int().min(0),
  program_year: z.number().positive(),
});

export default {
  components: {
    PageCard,
    BaseButton,
    ContractFormDetails,
    ContractFormPremiums,
    ContractFormTerritory,
    HorizontalFormSteps,
    AddAttachmentsTable,
  },

  data() {
    return {
      toast: useToast(),
      currentContractDetails: {
        crop: null,
        quantity: 0,
        quantity_units: null,
        contract_name: null,
        contract_description: null,
        program_name: null,
        program_year: null,
        deliver_by: null,
        delivery_location: null,
        elevator: null,
        payment_date: null,
        contract_partner_payment: null,
        contract_partner_payment_units: null,
        contract_total_value: null,
        client_name: null,
        client_email: null,
        additional_prior_year_records: 0,
      },
      currentContractPremiums: [],
      currentContractTerritory: {
        type: "MultiPolygon",
        coordinates: [],
      },
      currentContractAttachments: [],
      contractId: 0,
    };
  },
  computed: {
    currentStep() {
      if (this.$route.query.step > 4 || this.$route.query.step < 1) {
        this.handleStepChange(0);
      }
      return this.$route.query.step ? parseInt(this.$route.query.step) : 1;
    },
    newContractSteps() {
      return [
        {
          name: "Contract Details",
          status: this.stepStatus(1),
          id: 1,
        },
        {
          name: "Premiums",
          status: this.stepStatus(2),
          id: 2,
        },
        {
          name: "Territory",
          status: this.stepStatus(3),
          id: 3,
        },
        {
          name: "Attachments",
          status: this.stepStatus(4),
          id: 4,
        },
      ];
    },
    contractForSubmission() {
      return {
        ...this.currentContractDetails,
        territory: this.currentContractTerritory.coordinates.length
          ? this.currentContractTerritory
          : null,
      };
    },
  },
  methods: {
    handleStepChange(step) {
      this.$router.push({
        query: {
          step: step,
        },
      });
    },
    stepStatus(stepIndex) {
      if (stepIndex < this.currentStep) {
        return "complete";
      } else if (stepIndex == this.currentStep) {
        return "current";
      } else {
        return "inactive";
      }
    },
    async createContract() {
      // ensure the required data is present
      try {
        contractSchema.parse(this.contractForSubmission);
      } catch (error) {
        // log the error and show a toast message containing which part of the schema is missing
        console.error(error);
        // check which part of the schema is missing and show a toast message indicating the missing part
        let missingPart = error.errors[0].path[0];
        this.toast.error(`Missing required field: ${missingPart}`);
        return;
      }
      let newContract = await this.$store.dispatch(
        "createContract",
        this.contractForSubmission,
        true,
      );
      // for each of the premiums in the currentContractPremiums, submit the premium, then to that premium id submit the validation records
      for (let premium of this.currentContractPremiums) {
        // submitPremium is premium with a removed validation_records
        let submitPremium = {
          ...premium,
        };
        delete submitPremium.validation_records;
        let newPremium = await this.$store.dispatch("createContractPremium", {
          contractId: newContract.id,
          contractPremium: submitPremium,
        });
        for (let record of premium.validation_records) {
          await this.$store.dispatch("createPremiumValidationRecord", {
            premiumId: newPremium.id,
            validationRecord: record,
          });
        }
      }
      this.contractId = newContract.id;
      this.$refs.attachmentsTable.saveAttachments();
      this.$router.push({
        name: "buyer-assignment-new",
        query: { contractId: newContract.id },
      });
    },
  },
  watch: {},
};
</script>

<template>
  <div class="col-span-6 flex justify-end print:hidden">
    <base-button
      buttonText="Print"
      :marginLeft="false"
      @buttonClick="printReport"
    />
    <base-button
      buttonText="Download"
      :marginLeft="true"
      @buttonClick="exportXLSX"
    />
  </div>
  <span class="text-2xl font-bold"> {{ contract?.contract_name }} </span>
  <div class="flex">
    <div class="w-1/2 pl-4 pt-4">
      <div v-if="contract?.client_name?.length > 0">
        Buyer: {{ contract?.client_name }}
      </div>
      Goal: {{ contract?.quantity?.toLocaleString() }}
      {{ contract?.quantity_units }} of
      {{ contract?.crop }}
      <br />
      Contracted Quantity: {{ orderQuantity?.toLocaleString() }}
      {{ contract?.quantity_units }} of
      {{ contract?.crop }}
      <br />
    </div>
    <div class="w-96 pl-4">
      <apexchart
        type="radialBar"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </div>
  </div>
  <!-- {{ contract }} -->
  <span class="text-xl font-bold uppercase">Assignments</span>
  <div v-for="offer in offers" :key="offer.id">
    {{ offer.org_name }}: {{ offer.quantity }} {{ contract.quantity_units }}
  </div>

  <hr class="mb-6" />
  <span class="text-xl font-bold uppercase">Order Summary</span>
  <div class="pl-4">
    <div>
      <div v-for="(order, idx) in contractOrders" :key="idx" class="mb-2">
        <div v-if="order?.buyer_signature && order?.grower_signature">
          {{ order?.quantity ? order.quantity : order?.capacity }}
          {{ contract?.quantity_units }} of {{ contract?.crop }} from
          {{ order?.seller_details?.name }} on order #
          {{ order?.id }}
          <div class="pl-4">
            {{
              premiumIdx(order.premium) && premiumIdx(order.premium) > -1
                ? "Premium " + premiumIdx(order.premium) + ": "
                : ""
            }}{{ premiumSummary(order.premium) }}
          </div>
          <div class="pl-4">
            <span class="text-l font-bold">Deliveries:</span>
            <div
              v-for="(delivery, idx) in deliveries?.filter(
                (delivery) => delivery.crop_order == order.id,
              )"
              :key="idx"
              class="pl-4"
            >
              {{ delivery.quantity_delivered }}
              {{ delivery.quantity_units }}
              <!--  delivered on
                {{ delivery.updated.toLocaleString("en", dateFormat) }} -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="!print:break-before-page pt-8"></div>
  <hr />

  <span class="text-xl font-bold uppercase">Field Details</span>

  <table class="text-bold border-separate border-spacing-4 text-left">
    <tr>
      <th>Grower</th>
      <th>Farm</th>
      <th>Field</th>
      <th>Crop</th>
      <th>Crop Year</th>
      <th>Premium</th>
    </tr>
    <template v-for="allocation in contractAllocations" :key="allocation.id">
      <template v-for="crop in allocation.crops" :key="crop.id">
        <tr>
          <td>{{ getGrowerName(allocation?.crop_order) }}</td>
          <td>
            {{ allocation?.shared_data?.field_details?.properties?.farm }}
          </td>
          <td>
            {{ allocation?.shared_data?.field_details?.properties?.name }}
          </td>
          <td>{{ crop?.crop_type }}</td>
          <td>
            {{ crop?.crop_year }}
          </td>
          <td>
            {{ allocation?.premiumId ? premiumIdx(allocation.premiumId) : "" }}
          </td>
        </tr>
        <tr>
          <td colspan="4" v-if="crop?.record_details?.length > 0">
            <div v-for="record in crop?.record_details" :key="record">
              <div v-html="formatRecord(record)"></div>
            </div>
          </td>
          <td colspan="4" v-else>
            <div class="pl-4 text-sm">No records reported</div>
          </td>
        </tr>
      </template>
    </template>
  </table>
  <!-- {{ contractOrders }} -->
</template>
<script>
import BaseButton from "@/components/buttons/BaseButton.vue";
import VueApexCharts from "vue3-apexcharts";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { tonsToBushels } from "@/components/composables/convertUnits";
import { summarizePremium } from "@/components/composables/scriptUtils";
import { exportToShapefile } from "@/components/composables/exportFile";
const RecordTypeDefintions = require("@/layouts/grower-portal/records/RecordTypes.json");
export default {
  components: {
    BaseButton,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      // Submit
      dateFormat: {
        weekday: "short",
        month: "2-digit",
        day: "numeric",
      },
      yearFormat: {
        year: "numeric",
      },
    };
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    contract() {
      return this.$store.getters.getContractById(this.$route.params.contractId);
    },
    offers() {
      let offers = this.$store.getters
        .getControllerMarketOffers()
        ?.filter((offer) => {
          return offer.buyer_contract === this.contract?.id;
        });
      for (let offer of offers) {
        let affiliate = this.$store.getters.getControllerMarketAffiliateById(
          offer.org,
        );
        console.log("affiliate", affiliate, offer.org);
        offer.org_name = affiliate?.affiliate_details.name;
      }
      return offers;
    },
    contractOrders() {
      let orders = [];
      for (let offer of this.offers) {
        orders = orders
          .concat(
            this.$store.getters.getControllerCropOrdersByMarketOffer(offer.id),
          )
          ?.filter((order) => {
            return order.status != "Canceled";
          });
      }
      return orders;
    },
    contractAllocations() {
      let allocations = [];
      for (let order of this.contractOrders) {
        let oa = this.$store.getters.getControllerAllocationsByOrder(order.id);
        allocations = allocations.concat(oa);
      }
      let cropControllerRecords = [];
      for (let crop of this.allControllerRecords) {
        let recordCount = 0;
        if (crop.harvest_range_end) {
          crop.crop_year = new Date(crop.harvest_range_end).getFullYear();
        } else {
          crop.crop_year = new Date().getFullYear();
        }
        for (let record of crop.record_details) {
          if (record.category == "NDVI") continue;
          recordCount++;
        }
        if (recordCount > 0) cropControllerRecords.push(crop);
      }
      for (let allocation of allocations) {
        if (this.contractOrders) {
          allocation.premiumId = this.contractOrders.find(
            (order) => order.id == allocation.crop_order,
          )?.premium;
        }
        // put in records, to distinguish from the old records in shared_data.
        allocation.crops = cropControllerRecords.filter(
          (record) => record.allocation === allocation.id,
        );
        allocation.crops.sort((a, b) => a.crop_year - b.crop_year);
      }
      //   data.sort((a, b) => a.city.localeCompare(b.city) || b.price - a.price);
      allocations = allocations.sort(
        (a, b) =>
          a.shared_data.field_details.properties.farm?.localeCompare(
            b.shared_data.field_details.properties.farm,
          ) ||
          a.shared_data.field_details.properties.name?.localeCompare(
            b.shared_data.field_details.properties.name,
          ),
      );
      return allocations;
    },
    allControllerRecords() {
      return this.$store.getters.getAllControllerAllocationRecords;
    },

    // SUMMARY DATA FOR CHART AND HEADER
    orderQuantity() {
      let quantity = 0;
      for (let order of this.contractOrders) {
        quantity += order.quantity;
      }
      return quantity;
    },

    allocatedQuantity() {
      let quantity = 0;
      if (!this.contract?.quantity) return 0;

      for (let allocation of this.contractAllocations) {
        if (this.contract.quantity_units === "acres") {
          quantity += allocation.acres;
        } else {
          if (
            allocation.shared_data?.crop_details?.crop_type &&
            allocation.shared_data?.crop_details?.details?.tpa
          ) {
            let qpa = tonsToBushels(
              allocation.shared_data?.crop_details?.crop_type,
              allocation.shared_data?.crop_details?.details?.tpa,
            );
            quantity += allocation.shared_data?.field_allocated_acres * qpa;
          }
        }
      }
      return quantity;
    },
    allocatedPct() {
      if (!this.contract?.quantity) return 0;
      return (this.allocatedQuantity / this.contract.quantity) * 100;
    },
    orderedPct() {
      if (!this.contract?.quantity) return 0;
      return (this.orderQuantity / this.contract.quantity) * 100;
    },

    deliveries() {
      let deliveries = [];
      for (let order of this.contractOrders) {
        for (let delivery of this.$store.getters.getControllerDeliveriesByOrder(
          order.id,
        )) {
          deliveries.push(delivery);
        }
      }
      return deliveries;
    },
    deliveredQuantity() {
      let quantity = 0;
      for (let delivery of this.deliveries) {
        quantity += delivery.quantity_delivered;
      }
      return quantity;
    },
    deliveredUnits() {
      let units = "";
      for (let delivery of this.deliveries) {
        let deliveryUnit = delivery.quantity_units;
        if (deliveryUnit.toLowerCase().indexOf("bushel") > -1)
          deliveryUnit = "bu";
        if (units.indexOf(deliveryUnit) == -1) {
          if (units.length > 0) units += ", ";
          units += deliveryUnit;
        }
      }
      return units;
    },
    deliveredPct() {
      if (!this.contract?.quantity) return 0;
      if (this.contract.quantity_units === "acres") {
        return -1;
      }
      return (this.deliveredQuantity / this.contract.quantity) * 100;
    },

    series() {
      let series = [this.orderedPct, this.allocatedPct];
      if (this.deliveredPct > 0) series.push(this.deliveredPct);
      return series;
    },
    chartOptions() {
      let labels = [
        this.orderedPct.toFixed(0) + "% Ordered",
        this.allocatedPct.toFixed(0) + "% Allocated",
      ];
      if (this.deliveredPct > 0)
        labels.push(this.deliveredPct.toFixed(0) + "% Delivered");

      return {
        colors: ["#009aa6", "#c67bc4", "#65f057"],
        chart: {
          height: 350,
          type: "radialBar",
        },
        legend: {
          show: true,
        },
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 10,
              size: "30%",
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: "16px",
              },
              // value: {
              //   show: true,
              //   fontSize: "12px",
              // },
            },
          },
        },
        labels: labels,
        // labels: ["white", "white", "white"],
      };
    },
  },
  methods: {
    printReport() {
      window.print();
    },
    getGrowerName(orderId) {
      let order = this.contractOrders.find((order) => order.id == orderId);
      if (!order?.seller_details) return "";
      return order.seller_details.name;
    },

    async exportXLSX() {
      //Get all the records for this contract
      let records = [];
      for (let allocation of this.contractAllocations) {
        for (let crop of allocation.crops)
          if (crop.record_details) {
            for (let record of crop.record_details) {
              // loop through the record payload and add the details to the record object at the top level
              record.allocation_id = allocation.id;
              record.field_id = allocation.shared_data.field_details.id;
              record.field_name =
                allocation.shared_data.field_details.properties.name;
              record.field_farm =
                allocation.shared_data.field_details.properties.farm;
              record.crop_type = crop.crop_type;
              record.order_id = allocation.crop_order;
              let order = this.contractOrders.find(
                (order) => order.id == record.order_id,
              );
              record.grower_id = order.seller_details.id;
              record.grower_name = order.seller_details.name;
              record.crop_year = crop.crop_year;
              // if (order.offer) {
              //   let offer = this.offers.find((offer) => offer.id == order.offer);
              //   record.offer_id = offer.id;
              //   record.contract_id = offer.buyer_contract;
              //   record.contract_name = offer.contract_name;
              // } else {
              //   record.offer_id = null;
              //   record.contract_id = null;
              //   record.contract_name = null;
              // }
              records.push(record);
            }
          }
      }
      // for each item in recordTypeDefinitions, create an array for that recordType
      // each record in records, add the record to the array for the recordType == record.category
      // return the array of arrays
      let recordsForExport = [];
      for (let recordType of RecordTypeDefintions) {
        let recordsOfType = records.filter(
          (record) => record.category == recordType.category,
        );
        recordsForExport.push(recordsOfType);
      }
      // remove any arrays that are length 0
      recordsForExport = recordsForExport.filter(
        (recordType) => recordType.length > 0,
      );

      // 1) Create a new Excel workbook
      const workbook = new ExcelJS.Workbook();
      //   const maxSheetRows = 2000; // Adjust as needed
      // 2) cycle through each record type on the contract and create a worksheet for each
      console.log("recordsForExport", recordsForExport);
      for (let recordType of recordsForExport) {
        let recordTypeDefinition = RecordTypeDefintions.find(
          (def) => def.category === recordType[0].category,
        );
        const worksheet = workbook.addWorksheet(recordType[0].category);
        // 3) Add the headers to the worksheet
        let headers = [
          "Grower",
          "Allocation ID",
          "Field ID",
          "Farm",
          "Field",
          "Crop",
          "Crop Year",
        ];
        for (let attribute of recordTypeDefinition.data.payload) {
          if (attribute.component === "RepeatedSection") {
            let subPayload = attribute.payload;
            for (let subAttribute of subPayload) {
              headers.push(subAttribute.label);
            }
          } else {
            headers.push(attribute.label);
          }
        }
        worksheet.addRow(headers);
        // 4) Add the data to the worksheet
        for (let record of recordType) {
          let row = [
            record.grower_name,
            record.allocation_id,
            record.field_id,
            record.field_name,
            record.field_farm,
            record.crop_type,
            record.crop_year,
          ];
          let padding = 5; //reserve space for grower, allocation, field, farm, and category
          for (let attribute of recordTypeDefinition.data.payload) {
            if (attribute.component === "RepeatedSection") {
              let subPayload = attribute.payload;
              for (
                let repeatedRow = 0;
                repeatedRow < record.payload[attribute.value]?.length;
                repeatedRow++
              ) {
                if (repeatedRow > 0) {
                  worksheet.addRow(row);
                  row = new Array(padding + 1).fill(""); // skip to the first repeating section column
                }
                for (let subAttribute of subPayload) {
                  let value =
                    record.payload[attribute.value][repeatedRow][
                      subAttribute.value
                    ];
                  if (value === undefined || value === null) {
                    value = "";
                  }
                  row.push(value);
                }
              }
            } else {
              let value = record.payload[attribute.value];
              if (value === undefined || value === null) {
                value = "";
              }
              row.push(value);
            }
          }
          worksheet.addRow(row);
        }
        // 5) Auto-size the columns
        worksheet.columns.forEach((column) => {
          let maxLength = 0;
          column.eachCell((cell) => {
            const columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
              maxLength = columnLength;
            }
          });
          column.width = maxLength < 10 ? 10 : maxLength;
        });
      }
      // 6) Generate the Excel file and trigger download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, this.contract.contract_name + ".xlsx");
      this.exportShapeFile();
    },

    exportShapeFile() {
      let fields = [];
      for (let allocation of this.contractAllocations) {
        if (allocation.shared_data?.field_details) {
          let field = allocation.shared_data.field_details;
          let order = this.contractOrders.find(
            (order) => order.id == allocation.crop_order,
          );
          field.properties.grower = order.seller_details.name;
          if (allocation.shared_data?.crop_details) {
            field.properties.current_crop = allocation.shared_data.crop_details;
          }
          fields.push(field);
        }
      }
      // console.log("fields", fields);
      let fileName = this.contract.contract_name + "_shapefile.zip";
      exportToShapefile(fields, fileName);
    },

    // formatRecord takes a record object and returns an HTML table with the record data
    formatRecord(record) {
      let str =
        "<table class='text-left text-sm ml-4 border-separate border-spacing-2 border border-gray-400 dark:border-gray-500'> <tr>";
      let recordTypeDefinition = RecordTypeDefintions.find(
        (def) => def.category === record.category,
      );
      str += `<th>Category</th>`;
      let padding = 1; //reserve space for category
      let foundRepeatingSection = false;
      for (let attribute of recordTypeDefinition.data.payload) {
        if (!record.payload[attribute.value]) {
          //Skip if this key is not in the record payload
          continue;
        }
        if (attribute.component === "RepeatedSection") {
          foundRepeatingSection = true;
          let subPayload = attribute.payload;
          for (let subAttribute of subPayload) {
            // include all columns in the repeating section because we don't which rows might have data
            let label = subAttribute.label;
            str += `<th>${label}</th>`;
          }
        } else {
          if (!foundRepeatingSection) {
            padding += 1;
          }
          let label = attribute.label;
          str += `<th>${label}</th>`;
        }
      }
      str += "</tr>";
      str += "<tr>";
      str += `<td>${record["category"]}</td>`;
      // now load the data
      for (let attribute of recordTypeDefinition.data.payload) {
        if (!record.payload[attribute.value]) {
          //Skip if this key is not in the record payload
          continue;
        }
        if (attribute.component === "RepeatedSection") {
          let subPayload = attribute.payload;
          for (
            let row = 0;
            row < record.payload[attribute.value].length;
            row++
          ) {
            if (row > 0) {
              str += "</tr><tr>";
              str += "<td colspan='" + padding + "'></td>"; // skip to the first repeating section column
            }
            for (let subAttribute of subPayload) {
              let payload = record.payload[attribute.value][row];
              let value = payload[subAttribute.value];
              if (value === undefined || value === null) {
                value = "";
              }
              str += `<td>${value}</td>`;
            }
          }
        } else {
          let value = record.payload[attribute.value];
          str += `<td>${value}</td>`;
        }
      }
      str += "</tr></table>";
      // console.log("formatted record", str);
      return str;
    },
    premiumIdx(premiumId) {
      let premiums = this.contract.premiums;
      if (!premiums) return null;
      let index = premiums.findIndex((premium) => premium.id == premiumId);
      if (index > -1) return index + 1;
      return null;
    },
    premiumSummary(premiumId) {
      let premium = "";
      if (premiumId) {
        let premiumObj = this.$store.getters.getContractPremiumById(premiumId);
        if (!premiumObj) return "Premium not found";
        premium = summarizePremium(premiumObj);
        premium += "; " + premiumObj.terms;
        console.log("premium", premium, premiumObj, premiumId);
      }

      return premium;
    },
  },
};
</script>

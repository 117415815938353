<template>
  <!-- Discover panel -->
  <feature-flagged requires="gp-buy">
    <div
      v-if="offers?.length > 0"
      class="mb-4 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:gap-px sm:divide-y-0"
    >
      <discover-panel />
    </div>
  </feature-flagged>
  <feature-flagged requires="gp-sell">
    <div class="mb-4 hidden lg:flex">
      <offer-listview :maxOffers="3" />
    </div>
  </feature-flagged>
  <div class="flex w-full">
    <!-- Actions panel -->
    <div
      class="grow divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px"
      v-if="currentOrg && actions.length > 0"
    >
      <h2 class="sr-only" id="quick-links-title">Quick links</h2>
      <div
        v-for="(action, actionIdx) in actions"
        :key="action.name"
        :class="[
          actionIdx === 0
            ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
            : '',
          actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
          actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
          actionIdx === actions.length - 1
            ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
            : '',
          'group relative bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500',
        ]"
      >
        <component :is="action.type" :action="action" />
      </div>
    </div>

    <!-- </div> -->
    <feature-flagged requires="gp-buy">
      <div
        class="hidden lg:flex"
        v-if="actions.length > 0"
        :class="actions.length > 0 ? 'ml-4 w-48' : ''"
      >
        <discover-sidebar :gridColumns="actions.length > 0 ? '1' : '6'" />
      </div>
    </feature-flagged>
  </div>
  <!-- Sitenav panel -->
  <div class="hidden sm:block">
    <GrowerSitenavActions v-if="!isActiveProcess" />
  </div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  // AcademicCapIcon,
  BellIcon,
  // CashIcon,
  // ClockIcon,
  MenuIcon,
  // ReceiptRefundIcon,
  ClipboardListIcon,
  //olderIcon,
  // BookOpenIcon,
  TruckIcon,
  XIcon,
  FlagIcon,
  FilmIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";
import DashboardStatWidget from "@/components/widgets/DashboardStatWidget.vue";
import DashboardListWidget from "@/components/widgets/DashboardListWidget.vue";
import ChartWidget from "@/components/widgets/ChartWidget.vue";
import StatsPanel from "@/components/widgets/StatsPanel.vue";
import MapWidget from "@/components/widgets/MapWidget.vue";
import OfferListview from "@/pages/grower-portal/sell/produce/offers/GrowerPortalMarketOffersListview.vue";
import DiscoverPanel from "@/layouts/grower-portal/discover/dashboard/DiscoverPanel.vue";
import DiscoverSidebar from "@/layouts/grower-portal/discover/dashboard/DiscoverSidebar.vue";
import GrowerSitenavActions from "@/layouts/grower-portal/GrowerSitenavActions.vue";
import FeatureFlagged from "@/components/containers/FeatureFlagged.vue";
export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverOverlay,
    PopoverPanel,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    MenuIcon,
    SearchIcon,
    TruckIcon,
    XIcon,
    FlagIcon,
    FilmIcon,
    DashboardStatWidget,
    DashboardListWidget,
    ChartWidget,
    StatsPanel,
    MapWidget,
    OfferListview,
    DiscoverPanel,
    DiscoverSidebar,
    GrowerSitenavActions,
    FeatureFlagged,
  },
  data() {
    return {
      //actions: [],
    };
  },
  computed: {
    offers() {
      let offers = this.$store.getters.getGrowerMarketOffers({});
      return offers;
    },
    isActiveProcess() {
      return this.$store.getters.getGrowerActionsActiveProcess;
    },
    currentOrg() {
      return this.$store.getters.getCurrentOrg;
    },
    fields() {
      let fields = this.$store.getters.getAllFields;
      fields = fields.filter((field) => field.geometry);
      return fields;
    },
    layers() {
      let layers = [];
      // let equipment = this.$store.state.equipment.equipment;
      // let equipmentJSON = {
      //   type: "FeatureCollection",
      //   features: equipment.map((asset) => ({
      //     type: "Feature",
      //     id: asset.id,
      //     geometry: asset.location,
      //     properties: {
      //       name: asset.alias,
      //       link: "/app/equipment/" + asset.id,
      //     },
      //   })),
      // };
      // let equipmentLayer = {
      //   name: "Equipment",
      //   geojson: equipmentJSON,
      // };
      // layers.push(equipmentLayer);

      let fieldJSON = {
        type: "FeatureCollection",
        features: this.fields.map((field) => ({
          type: "Feature",
          id: field.id,
          geometry: field.geometry,
          properties: {
            name: field.properties.name,
            link: "/app/fields/" + field.id,
          },
        })),
      };
      let fieldLayer = {
        name: "Fields",
        geojson: fieldJSON,
        //style:{fillColor:"red", opacity: 1, weight: 1, color: "green",}
      };
      //console.log("fields layer", fieldLayer);
      layers.push(fieldLayer);
      //NDVI
      const records = this.$store.state.records.records
        .filter((rec) => rec.category === "NDVI")
        .map((rec) => {
          const img_data = rec.payload;
          return {
            field: rec.field,
            url: img_data.ndvi_image,
            bounds: [
              [img_data.location[1], img_data.location[0]],
              [img_data.location[3], img_data.location[2]],
            ],
          };
        });
      let ndviImages = [];
      records.forEach((rec) => {
        ndviImages.push({
          url: rec.url,
          bounds: rec.bounds,
        });
      });
      let NDVILayer = {
        name: "Plant Vigor",
        images: ndviImages,
        key: "<img width='50' src='/img/pictures/ndvi_key.png' />",
        // key: "/img/pictures/ndvi_key.png",
      };
      layers.push(NDVILayer);
      return layers; // [{"name": "tractor","geojson":this.geojson}];
    },
    actions() {
      if (!this.currentOrg) return [];
      let actions = [];
      // let testing = false;

      let tasks = this.$store.getters.getActivities();
      let recommendations = this.$store.getters.getRecommendations();
      // let users = this.$store.state.users.users;
      let reservations = this.$store.getters.getDerivedGrowerOrders({});

      let orders = this.$store.getters.getGrowerCropOrders(
        ["In Progress", "Internal"],
        true,
      );

      let agreements = this.$store.getters.getDerivedGrowerQuotes({
        filter: { service_category: "Hauling" },
      });
      let haulingOrders = reservations.filter((order) => {
        return agreements.some((agreement) => {
          return agreement.id === order.agreement;
        });
      });

      let num_reservations = haulingOrders.filter(
        (reservation) => reservation.status == "buyer_submitted",
      ).length;
      let taskStates = {};
      let tasksDue = 0;

      let derivedFields = this.fields.map((field) => {
        let sold_acres = this.$store.getters.getSumAllocationAcresByCropId(
          field.properties?.current_crop?.id,
        );
        // console.log("sold_acres", sold_acres, field.properties.acres);
        return {
          ...field,
          sold_acres: Math.round(sold_acres * 100) / 100,
          unsold_acres:
            Math.round((field.properties.acres - sold_acres) * 100) / 100,
        };
      });
      let crop_variety = {}; // variety:{sold:acres, unsold:acres}
      derivedFields.forEach((field) => {
        let key = field.properties?.crop;
        if (field?.properties?.current_crop?.details?.variety)
          key =
            field.properties.current_crop.details.variety +
            " " +
            field.properties?.crop;
        if (!crop_variety[key]) {
          crop_variety[key] = {};
          crop_variety[key]["sold"] = Math.round(field.sold_acres);
          crop_variety[key]["unsold"] = Math.round(field.unsold_acres);
        } else {
          crop_variety[key]["sold"] += Math.round(field.sold_acres);
          crop_variety[key]["unsold"] += Math.round(field.unsold_acres);
        }
      });
      let variety_sold = [];
      let variety_unsold = [];
      // console.log("before loop", crop_variety);
      for (let variety in crop_variety) {
        // console.log(
        //   "variety_sold",
        //   crop_variety[variety],
        //   crop_variety[variety].sold
        // );
        variety_sold.push(crop_variety[variety].sold);
        variety_unsold.push(crop_variety[variety].unsold);
      }
      // console.log(
      //   "dashboard",
      //   crop_variety,
      //   Object.keys(crop_variety),
      //   variety_sold,
      //   variety_unsold
      // );
      tasks.forEach((task) => {
        taskStates[task.status] = taskStates[task.status] + 1 || 1;
        var curr = new Date(); // get current date
        var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
        var last = first + 6; // last day is the first day + 6
        var firstday = new Date(curr.setDate(first));
        var lastday = new Date(curr.setDate(last));
        var deadline = new Date(task.deadline);
        if (!task.deadline) tasksDue++;
        // no deadline means it could be done now.
        else if (deadline >= firstday && deadline <= lastday) tasksDue++;
      });
      let num_inquiries = this.$store.getters.getPartnerInquiries?.length;
      if (!num_inquiries) num_inquiries = 0;
      // console.log(
      //   "dashboard",
      //   tasks,
      //   tasks.length,
      //   taskStates,
      //   Object.keys(taskStates),
      //   Object.values(taskStates),
      //   "varieties",
      //   crop_variety,
      //   Object.keys(crop_variety),
      //   derivedFields,
      //   variety_sold,
      //   variety_unsold,
      //   "users",
      //   users,
      //   reservations
      // );
      if (
        this.currentOrg.feature_flags.includes("gp-fms") &&
        (tasks.length > 0 ||
          recommendations.length > 0 ||
          num_reservations > 0 ||
          num_inquiries > 0)
      ) {
        actions.push({
          type: "StatsPanel",
          icon: FlagIcon,
          name: "Operations Summary",
          blurb: "Quick facts about your farm.",
          // value: tasksDue,
          // blurb: "What orders do I have today?",
          // routeName: "activities", // disable full widget link to allow links on each stat.
          iconForeground: "text-teal-700",
          iconBackground: "bg-teal-50",
          stats: [
            {
              title: "Activities",
              desc: "Things you need to manage this week.",
              value: tasksDue,
              // desc: "Varieties allocated on orders shared with me",
              id: 1,
              routeName: "activities",
            },
            {
              title: "Recommendations",
              value: recommendations.length,
              desc: "Needing review",
              id: 2,
              routeName: "grower-recommendations",
            },
            {
              title: "Hauling",
              value: num_reservations,
              desc: "Reservations scheduled",
              id: 3,
              routeName: "grower-portal-hauling-dashboard",
            },
            {
              title: "Inquiries",
              value: num_inquiries,
              desc: "Crop purchase inquiries and negotiations",
              id: 4,
              routeName: "grower-portal-produce-inquiries",
            },
          ],
        });
        // // actions.push(action);
        // actions.push({
        //   type: "DashboardStatWidget",
        //   icon: ClipboardListIcon,
        //   name: "Activities",
        //   value: tasksDue,
        //   blurb: "Things you need to manage this week.",
        //   routeName: "activities",
        //   iconForeground: "text-teal-700",
        //   iconBackground: "bg-teal-50",
        // });
        // actions.push({
        //   type: "DashboardStatWidget",
        //   icon: FlagIcon,
        //   name: "Recommendations",
        //   value: recommendations.length,
        //   blurb: "Review recommendations to improve your operations",
        //   routeName: "recommendations",
        //   iconForeground: "text-teal-700",
        //   iconBackground: "bg-sky-50",
        // });
      }
      if (
        this.fields.length > 0 &&
        this.layers
        // &&         this.currentOrg.feature_flags.includes("gp-fms")
      ) {
        // NDVI view
        actions.push({
          type: "MapWidget",
          icon: FilmIcon,
          name: "Field Map",
          blurb: "View your fields and plant vigor.",
          routeName: "activities",
          layers: this.layers,
        });
      }
      if (
        this.currentOrg.feature_flags.includes("gp-fms") &&
        tasks.length > 0
      ) {
        actions.push({
          type: "ChartWidget",
          icon: ClipboardListIcon,
          name: "Task Completion",
          value: tasksDue,
          blurb: "How much work is done?",
          routeName: "activities",
          iconForeground: "text-teal-700",
          iconBackground: "bg-teal-50",
          chartOptions: {
            chart: {
              width: 380,
              type: "donut",
            },
            labels: Object.keys(taskStates), // ["Team A", "Team B", "Team C", "Team D", "Team E"],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          },
          series: Object.values(taskStates), //[44, 55, 13, 43, 22],
        });
      }
      if (
        this.currentOrg.feature_flags.includes("gp-sell") &&
        !this.currentOrg.feature_flags.includes("gp-fms") &&
        orders.length > 0
      ) {
        let orders = this.$store.getters.getGrowerCropOrders(
          ["In Progress", "Internal", "Completed", "Inquiry"],
          true,
        );
        let inProgress = orders.filter(
          (order) => order.status === "In Progress",
        );
        let inquiries = orders.filter(
          (order) => order.status === "Inquiry" && order.offer,
        );
        let offers = this.$store.getters.getGrowerMarketOffers({});

        actions.push({
          type: "StatsPanel",
          icon: FlagIcon,
          name: "Orders Summary",
          blurb:
            "Current opportunities to sell crops or get paid to follow sustainable farming practices.",
          // value: tasksDue,
          // blurb: "What orders do I have today?",
          // routeName: "activities", // disable full widget link to allow links on each stat.
          iconForeground: "text-teal-700",
          iconBackground: "bg-teal-50",
          stats: [
            {
              title: "Orders",
              desc: "In Progress",
              value: inProgress?.length,
              // desc: "Varieties allocated on orders shared with me",
              id: 1,
              routeName: "grower-portal-produce-orders",
            },
            {
              title: "Inquiries",
              value: inquiries?.length,
              desc: "Pending Approval",
              id: 2,
              routeName: "grower-portal-produce-orders",
            },
            {
              title: "Offers",
              value: offers?.length,
              desc: "Offers from buyers",
              id: 4,
              routeName: "grower-portal-produce-orders",
            },
          ],
        });
      }
      if (
        this.fields.length > 0 &&
        orders.length > 0 &&
        this.currentOrg.feature_flags.includes("gp-fms")
      ) {
        actions.push({
          type: "ChartWidget",
          icon: ClipboardListIcon,
          name: "Crops Sold",
          value: tasksDue,
          blurb: "Acres allocated by variety.",
          routeName: "grower-portal-produce-allocations",
          iconForeground: "text-teal-700",
          iconBackground: "bg-teal-50",
          series: [
            {
              name: "Sold",
              data: variety_sold, //[44, 55, 41, 67, 22, 43],
            },
            {
              name: "Unsold",
              data: variety_unsold, // [13, 23, 20, 8, 13, 27],
            },
            // {
            //   name: "PRODUCT C",
            //   data: [11, 17, 15, 0, 21, 14],
            // },
            // {
            //   name: "PRODUCT D",
            //   data: [0, 7, 25, 0, 22, 8],
            // },
          ],
          chartOptions: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: true,
              },
              zoom: {
                enabled: true,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                //borderRadius: 20,
              },
            },
            xaxis: {
              type: "category",
              categories: Object.keys(crop_variety),
              labels: { trim: true, offsetX: 2 },
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          },
        });
      }

      // if (
      //   tasks?.length == 0 ||
      //   users?.length == 1 ||
      //   reservations?.length == 0 ||
      //   fields?.length == 0 ||
      //   testing
      // ) {
      //   let taskStatus,
      //     userStatus,
      //     reservationStatus,
      //     fieldStatus = "incomplete";

      //   if (tasks?.length > 0) taskStatus = "complete";
      //   if (users?.length > 1) userStatus = "complete";
      //   if (fields?.length > 0) fieldStatus = "complete";
      //   if (reservations?.length > 0) reservationStatus = "complete";
      //   let blurb = "Let's get up and running!";
      //   // console.log("dashboard", fields, tasks, users, reservations);
      //   if (
      //     taskStatus == "complete" &&
      //     userStatus == "complete" &&
      //     fieldStatus == "complete" &&
      //     userStatus == "complete"
      //   )
      //     blurb = "Looks like you are all set. Great job!";
      //   actions.push({
      //     type: "DashboardListWidget",
      //     icon: BookOpenIcon,
      //     name: "Onboarding",
      //     blurb: blurb,
      //     routeName: "",
      //     iconForeground: "text-teal-700",
      //     iconBackground: "bg-teal-50",
      //     list: [
      //       {
      //         title: "add a field",
      //         status: fieldStatus,
      //         routeName: "fields",
      //       },
      //       {
      //         title: "add a user",
      //         status: userStatus,
      //         routeName: "grower-portal-settings",
      //       },
      //       {
      //         title: "assign a task",
      //         status: taskStatus,
      //         routeName: "activities",
      //       },
      //       {
      //         title: "book a reservation",
      //         status: reservationStatus,
      //         routeName: "grower-portal-hauling-dashboard",
      //       },
      //     ],
      //   });
      // }
      // if (reservations) {
      //   // let num_fields = fields.length;
      //   // let percent_value = 0;
      //   // if (num_reservations > 0 && num_fields > 0) {
      //   //   percent_value = (num_reservations / num_fields) * 100;
      //   // }
      //   actions.push({
      //     type: "DashboardStatWidget",
      //     icon: TruckIcon,
      //     name: "Hauling",
      //     value: `${num_reservations}`,
      //     blurb: "Hauling Reservations Scheduled",
      //     routeName: "grower-portal-hauling-dashboard",
      //     iconForeground: "text-sky-700",
      //     iconBackground: "bg-sky-50",
      //   });
      // }
      // console.log("actions", actions);
      return actions;
    },
  },
};
</script>

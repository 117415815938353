<template>
  <table-card>
    <template #header>
      <table-head
        :columns="[
          { name: 'name', label: 'Grower', filtered: false },
          { name: 'numberOrders', label: 'Orders', filtered: false },
          //   { name: 'quantity', label: 'Quantity Assigned', filtered: false },
          { name: 'actions', label: '', filtered: false },
          { name: 'actions', label: '', filtered: false },
        ]"
        :collection="growers"
        @update:derived-collection="derivedGrowers = $event"
        :defaultSort="defaultSort"
        :defaultFilter="defaultFilter"
        :limit="50"
      >
        <template #toolbarButtons>
          <div class="flex">
            <table-toolbar-button
              buttonText="Add Grower"
              :usePlusIcon="true"
              @buttonClick="addGrower"
            />
          </div>
        </template>
      </table-head>
    </template>
    <tbody>
      <tr v-for="g in derivedGrowers" :key="g.id">
        <table-data td-label="Name"> {{ g.name }} </table-data>
        <table-data td-label="Orders">
          {{ g.numberOrders }}
        </table-data>
        <table-data class="text-center">
          <router-link
            :to="{
              name: 'buyer-bp-affiliate-grower-report',
              params: { growerId: g.id },
            }"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            View
          </router-link>
        </table-data>
        <table-data v-if="g.isExternal" class="text-center">
          <button
            @click="impersonateGrower(g.id, 'grower-portal-produce-orders')"
            class="pl-3 font-medium text-violet-500 hover:text-twilight-900 sm:pr-6"
          >
            Impersonate
          </button>
        </table-data>
        <table-data v-else> </table-data>
      </tr>
    </tbody>
  </table-card>
</template>

<script>
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";

export default {
  components: {
    TableToolbarButton,
    TableHead,
    TableCard,
    TableData,
  },
  data() {
    return {
      derivedGrowers: [],
      defaultFilter: {},
      defaultSort: { numberOrders: "desc" },
    };
  },
  computed: {
    orders() {
      return this.$store.getters.getDerivedBuyerCropOrders({});
    },
    orderGrowerMapping() {
      let orderGrowerIds = [];
      this.orders.forEach((order) => {
        orderGrowerIds.push(order.seller_details.id);
      });
      return orderGrowerIds;
    },
    growers() {
      let orgs = this.$store.state.memberOrgs.memberOrgs;
      let orgsWithOrders = [];
      // Iterate through the orgs and add the count of orders for each org the org object and then push it to the growers array
      orgs.forEach((org) => {
        let externalOrg = this.$store.getters.getSharedOrgById(org.id);
        let grower = {
          id: org.id,
          name: org.name,
          numberOrders: this.orderGrowerMapping.filter((id) => id === org.id)
            .length,
          // quantity_units: "Orders",
          isExternal: externalOrg ? true : false,
        };
        orgsWithOrders.push(grower);
      });
      // sort by number of orders
      orgsWithOrders.sort((a, b) => b.numberOrders - a.numberOrders);
      return orgsWithOrders;
    },
    isFirstParty() {
      return this.$store.getters.currentOrgIsFirstParty;
    },
  },
  methods: {
    addGrower() {
      this.$router.push({ name: "buyer-bp-affiliate-growers-new" });
    },
    async impersonateGrower(orgId, page) {
      await this.$store.dispatch("loginSwitchContext", { shared_org: orgId });
      if (page) {
        this.$router.push({ name: page });
      }
    },
  },
};
</script>

<style></style>

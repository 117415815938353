<template>
  <modal :show="modalIsActive" title="Importing Spreadsheet">
    <div v-html="modalDescription" class="prose"></div>
    <div class="pt-8" v-if="modalIsActive">
      <div class="pl-32"><loading-spinner /></div>
    </div>
  </modal>
  <!-- <BaseButton @buttonClick="exportRecordsTemplate" buttonText="Edit in Excel" /> -->
  <TableToolbarButton
    @buttonClick="exportRecordsTemplate"
    buttonText="Edit Records in Excel"
  />
  <!-- <input type="file" @change="handleFileInput" /> -->
  <file-select
    class="h-full"
    buttonText="Upload Changes"
    accept="xlsx"
    @input="handleFileInput"
    :showSelectedFile="false"
    button-type="table-toolbar"
  />
</template>

<script>
// import BaseButton from "@/components/buttons/BaseButton.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import FileSelect from "@/components/form/FileSelect.vue";
const RecordTypeDefinitions = require("@/layouts/grower-portal/records/RecordTypes.json");
let cropList = require("@/assets/croplist.js");
import { useToast } from "vue-toastification";
import Modal from "@/components/modals/PopupModal.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { recordResult } from "@/components/composables/scriptUtils.js";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";

export default {
  components: {
    // BaseButton,
    TableToolbarButton,
    FileSelect,
    Modal,
    LoadingSpinner,
  },
  props: {
    crops: {
      type: Array,
      required: true,
    },
    premiums: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      toast: useToast(),
      maxFieldYear: null,
      modalIsActive: false,
    };
  },
  methods: {
    getMixProductAttributes(category) {
      const recordType = RecordTypeDefinitions.find(
        (type) => type.category === category,
      );
      if (!recordType) return [];
      const mixProductsDefinition = recordType.data.payload.find(
        (item) => item.value === "mix_products",
      );
      return mixProductsDefinition?.payload || [];
    },
    async exportRecordsTemplate() {
      const workbook = new ExcelJS.Workbook();
      const maxSheetRows = 2000; // Adjust as needed

      // 0) Create the "Instructions" sheet at the front and add instructions
      const instructionsWorksheet = workbook.addWorksheet("Instructions");

      // Merge cells for a large title
      instructionsWorksheet.mergeCells("A1:E1");
      instructionsWorksheet.getCell("A1").value =
        "How to Fill Out This Records Template";
      instructionsWorksheet.getCell("A1").font = { bold: true, size: 16 };
      instructionsWorksheet.getCell("A1").alignment = { horizontal: "center" };

      // Instructions bullets
      const instructions = [
        "1) Please do not rename, reorder, or remove any worksheets. Doing so may prevent the import from functioning properly.",
        "2) The 'Fields' sheet holds your field data (Field, Farm, Crop Year, Crop Type, Acres). Make sure these values are accurate.",
        "3) Each other sheet corresponds to a specific record category (e.g., Fertilizer Application). Fill in data below row 1 (headers).",
        "4) Do not enter data for Crop Type, Acres, and Farm columns in record category sheets; instead use the drop downs for Field and Crop Year which will then compute the values.",
        "5) Some columns are hidden because they are used internally. Do not unhide or remove them.",
        "6) If a column has a dropdown, use the provided list. Enter dates in YYYY-MM-DD format where indicated.",
      ];

      instructions.forEach((text, index) => {
        const rowNumber = index + 2;
        instructionsWorksheet.mergeCells(`A${rowNumber}:E${rowNumber}`);
        instructionsWorksheet.getCell(`A${rowNumber}`).value = text;
        instructionsWorksheet.getCell(`A${rowNumber}`).alignment = {
          wrapText: true,
        };
      });

      // Adjust columns for readability
      instructionsWorksheet.columns = [
        { key: "A", width: 100 },
        { key: "B", width: 0 },
        { key: "C", width: 0 },
        { key: "D", width: 0 },
        { key: "E", width: 0 },
      ];
      //  Create a hidden "validations" worksheet for large lists
      const validationsWorksheet = workbook.addWorksheet("validations", {
        state: "veryHidden", // This hides the sheet from end users
      });

      // Add a header in A1:
      validationsWorksheet.getCell("A1").value = "Crop Options";

      // Populate the cropOptions in column A (starting from row 2)
      this.cropOptions.forEach((option, index) => {
        validationsWorksheet.getCell(`A${index + 2}`).value = option;
      });

      // 1) Create the "Fields" worksheet first so it appears first in the workbook.
      const fieldsWorksheet = workbook.addWorksheet("Fields");

      // 3) Create category worksheets and populate them
      for (const record of this.validation_records) {
        const recordTypeDefinition = RecordTypeDefinitions.find(
          (type) => type.category === record.category,
        );

        // Columns that must appear in every category sheet.
        // We won’t directly populate Crop ID/Type/Acres/Farm with values,
        // but we’ll insert formulas that reference the Fields sheet.
        const requiredAttributes = [
          "Crop ID",
          "Crop Type",
          "Acres",
          "Farm",
          "Field",
          "Crop Year",
          "Record ID",
        ];

        // If record is Fertilizer or Input Application, we also gather “mix product” attributes
        let mixProductAttributes = [];
        if (
          record.category === "Fertilizer Application" ||
          record.category === "Input Application"
        ) {
          mixProductAttributes = this.getMixProductAttributes(record.category);
          requiredAttributes.push(
            ...mixProductAttributes.map((attr) => attr.label),
          );
        }

        const otherPayloadAttributes = recordTypeDefinition
          ? recordTypeDefinition.data.payload
              .filter((attr) => attr.value !== "mix_products")
              .map((attr) => attr.label)
          : [];
        requiredAttributes.push(...otherPayloadAttributes);

        const worksheet = workbook.addWorksheet(record.category);

        // Define columns
        worksheet.columns = requiredAttributes.map((attr) => ({
          header: attr,
          key: attr,
          width: 20,
          hidden: attr === "Crop ID" || attr === "Record ID" ? true : false,
        }));

        // Populate rows for this category
        for (const crop of this.cropsForExport) {
          // Filter records to the current category
          const cropRecords = crop.records.filter(
            (cropRecord) => cropRecord.category === record.category,
          );

          // If no records exist, insert a single empty row
          if (!cropRecords || cropRecords.length === 0) {
            const emptyRow = Object.fromEntries(
              requiredAttributes.map((attr) => [attr, ""]),
            );
            emptyRow["Record ID"] = "";
            emptyRow["Field"] = crop.field;
            emptyRow["Crop Year"] = crop.crop_year;
            worksheet.addRow(emptyRow);
          } else {
            // For each existing record
            for (const cropRecord of cropRecords) {
              // Build a base row containing the top-level record fields
              const baseRow = {
                "Crop ID": "",
                "Crop Type": "",
                Acres: "",
                Farm: "",
                "Record ID": cropRecord.id || "",
                Field: crop.field,
                "Crop Year": crop.crop_year,
              };

              // Populate the *non-mix* payload attributes first
              otherPayloadAttributes.forEach((label) => {
                const attrDefinition = recordTypeDefinition.data.payload.find(
                  (a) => a.label === label,
                );
                if (attrDefinition) {
                  const key = attrDefinition.value;
                  baseRow[label] = cropRecord.payload?.[key] || "";
                } else {
                  baseRow[label] = "";
                }
              });

              // Now handle the mix_products array
              const mixProducts = cropRecord.payload?.mix_products;

              // If mix_products is empty or not an array, just create one row using baseRow
              if (!Array.isArray(mixProducts) || mixProducts.length === 0) {
                const singleRow = { ...baseRow };
                // Optionally fill in empty strings for all mixProduct attributes
                mixProductAttributes.forEach((attr) => {
                  singleRow[attr.label] = "";
                });
                worksheet.addRow(singleRow);
              } else {
                // If we have an array of mix products, create a row for each product item
                mixProducts.forEach((productObj) => {
                  // Start from the same "base" row data
                  const rowWithProduct = { ...baseRow };

                  // Populate the columns corresponding to this product
                  mixProductAttributes.forEach((attr) => {
                    rowWithProduct[attr.label] = productObj?.[attr.value] || "";
                  });

                  // Now add that row to the worksheet
                  worksheet.addRow(rowWithProduct);
                });
              }
            }
          }
        }

        // 4) Apply data validations
        if (recordTypeDefinition) {
          const payloadAttributes = recordTypeDefinition.data.payload;

          payloadAttributes.forEach((attr) => {
            const columnIndex = requiredAttributes.indexOf(attr.label) + 1;
            if (columnIndex <= 0) {
              console.warn(`Column not found for attribute: ${attr.label}`);
              return;
            }

            const column = worksheet.getColumn(columnIndex);
            const columnLetter = column.letter;
            const range = `${columnLetter}2:${columnLetter}${maxSheetRows}`;

            // Dropdown validations
            if (Array.isArray(attr.selectOptions)) {
              const options = attr.selectOptions.map((opt) =>
                typeof opt === "object" ? opt.value || opt : opt,
              );

              if (options.length > 0) {
                worksheet.dataValidations.add(range, {
                  type: "list",
                  allowBlank: true,
                  formulae: [`"${options.join(",")}"`],
                  showErrorMessage: true,
                  errorTitle: "Invalid Input",
                  error: "Please select a value from the dropdown.",
                });
              }
            }

            // Boolean
            if (attr.type === "boolean") {
              worksheet.dataValidations.add(range, {
                type: "list",
                allowBlank: true,
                formulae: ['"TRUE,FALSE"'],
                showErrorMessage: true,
                errorTitle: "Invalid Input",
                error: "Please select TRUE or FALSE.",
              });
            }

            // Date
            if (attr.type === "date") {
              worksheet.dataValidations.add(range, {
                type: "date",
                allowBlank: true,
                operator: "greaterThan",
                formulae: [new Date(2000, 0, 1).toISOString().split("T")[0]],
                showErrorMessage: true,
                errorTitle: "Invalid Date",
                error: "Please enter a valid date in YYYY-MM-DD format.",
              });
            }

            // Number
            if (attr.type === "number") {
              worksheet.dataValidations.add(range, {
                type: "decimal",
                allowBlank: true,
                operator: "greaterThanOrEqual",
                formulae: [0],
                showErrorMessage: true,
                errorTitle: "Invalid Number",
                error: "Please enter a valid number.",
              });
            }
          });
        }

        // Apply Field column validation
        const fieldColumnLetter = worksheet.getColumn("Field").letter;
        worksheet.dataValidations.add(
          `${fieldColumnLetter}2:${fieldColumnLetter}${maxSheetRows}`,
          {
            type: "list",
            allowBlank: true,
            formulae: [`Fields!$E$2:$E$${maxSheetRows}`],
            showErrorMessage: true,
            errorTitle: "Invalid Input",
            error: "Please select a valid Field.",
          },
        );

        // Apply Crop Year column validation
        const cropYearColumnLetter = worksheet.getColumn("Crop Year").letter;
        worksheet.dataValidations.add(
          `${cropYearColumnLetter}2:${cropYearColumnLetter}${maxSheetRows}`,
          {
            type: "list",
            allowBlank: true,
            formulae: [`Fields!$F$2:$F$${maxSheetRows}`],
            showErrorMessage: true,
            errorTitle: "Invalid Input",
            error: "Please select a valid Crop Year.",
          },
        );

        // 5) Insert MATCH formulas for "Crop ID," "Crop Type," "Acres," "Farm"
        //    using the new "LookupKey" column from the Fields sheet.
        const startRow = 2;
        const endRow = 200; // Adjust as desired.
        const matchFieldLetter = worksheet.getColumn("Field").letter; // often "E"
        const matchCropYearLetter = worksheet.getColumn("Crop Year").letter; // often "F"

        // The LookupKey is in column G of the Fields sheet if columns A-F are used:
        // A = Crop ID, B = Crop Type, C = Acres, D = Farm, E = Field, F = Crop Year, G = LookupKey
        // (Adjust if you reorder or add columns.)
        // Style
        // light red background to specific columns
        const lightFill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "A9A9A9" },
        };
        for (let rowIndex = startRow; rowIndex <= endRow; rowIndex++) {
          // Crop ID (column A)
          // Formula
          worksheet.getCell(`A${rowIndex}`).value = {
            formula: `IF(AND($${matchFieldLetter}${rowIndex}<>"" , $${matchCropYearLetter}${rowIndex}<>""),
          INDEX(Fields!$A:$A,
            MATCH($${matchFieldLetter}${rowIndex}&"||"&$${matchCropYearLetter}${rowIndex}, Fields!$G:$G, 0)
          ),
          ""
        )`,
          };
          // Style
          worksheet.getCell(`A${rowIndex}`).fill = lightFill;
          // Crop Type (column B)
          // Formula
          worksheet.getCell(`B${rowIndex}`).value = {
            formula: `IF(AND($${matchFieldLetter}${rowIndex}<>"" , $${matchCropYearLetter}${rowIndex}<>""),
          INDEX(Fields!$B:$B,
            MATCH($${matchFieldLetter}${rowIndex}&"||"&$${matchCropYearLetter}${rowIndex}, Fields!$G:$G, 0)
          ),
          ""
        )`,
          };
          // Style
          worksheet.getCell(`B${rowIndex}`).fill = lightFill;
          // Acres (column C)
          // Formula
          worksheet.getCell(`C${rowIndex}`).value = {
            formula: `IF(AND($${matchFieldLetter}${rowIndex}<>"" , $${matchCropYearLetter}${rowIndex}<>""),
          INDEX(Fields!$C:$C,
            MATCH($${matchFieldLetter}${rowIndex}&"||"&$${matchCropYearLetter}${rowIndex}, Fields!$G:$G, 0)
          ),
          ""
        )`,
          };
          // Style
          worksheet.getCell(`C${rowIndex}`).fill = lightFill;
          // Farm (column D)
          // Formula
          worksheet.getCell(`D${rowIndex}`).value = {
            formula: `IF(AND($${matchFieldLetter}${rowIndex}<>"" , $${matchCropYearLetter}${rowIndex}<>""),
          INDEX(Fields!$D:$D,
            MATCH($${matchFieldLetter}${rowIndex}&"||"&$${matchCropYearLetter}${rowIndex}, Fields!$G:$G, 0)
          ),
          ""
        )`,
          };
          // Style
          worksheet.getCell(`D${rowIndex}`).fill = lightFill;
        }
      }

      // 5) Populate the "Fields" sheet with base columns only + a hidden LookupKey
      const fieldsData = this.cropsForExport.map((crop) => ({
        "Crop ID": crop.crop_id,
        "Crop Type": crop.crop_type,
        Acres: crop.acres,
        Farm: crop.farm,
        Field: crop.field,
        "Crop Year": crop.crop_year,
      }));

      fieldsWorksheet.columns = [
        { header: "Crop ID", key: "Crop ID", width: 20 },
        { header: "Crop Type", key: "Crop Type", width: 20 },
        { header: "Acres", key: "Acres", width: 20 },
        { header: "Farm", key: "Farm", width: 20 },
        { header: "Field", key: "Field", width: 20 },
        { header: "Crop Year", key: "Crop Year", width: 20 },
        { header: "LookupKey", key: "LookupKey", width: 30, hidden: true },
      ];

      // Add rows for existing crops
      fieldsWorksheet.addRows(fieldsData);

      // Generate a formula in "LookupKey" column G for rows up to maxSheetRows
      // This concatenates Field (E) and Crop Year (F) with "||"
      for (let rowIndex = 2; rowIndex <= maxSheetRows; rowIndex++) {
        fieldsWorksheet.getCell(`G${rowIndex}`).value = {
          formula: `E${rowIndex}&"||"&F${rowIndex}`,
        };
      }

      // Hide Crop ID column in Fields sheet (optional)
      const cropIdColIndex =
        fieldsWorksheet.columns.findIndex((col) => col.header === "Crop ID") +
        1;
      if (cropIdColIndex > 0) {
        fieldsWorksheet.getColumn(cropIdColIndex).hidden = true;
      }

      //  Data validation for Crop Type to reference the "validations" sheet
      const cropTypeColumnLetter =
        fieldsWorksheet.getColumn("Crop Type").letter;
      const maxRow = this.cropOptions.length + 1; // e.g. 1 header + # of options
      fieldsWorksheet.dataValidations.add(
        `${cropTypeColumnLetter}2:${cropTypeColumnLetter}${maxSheetRows}`,
        {
          type: "list",
          allowBlank: true,
          formulae: [`validations!$A$2:$A$${maxRow}`],
          showErrorMessage: true,
          errorTitle: "Invalid Crop Type",
          error: "Please select a valid Crop Type from the list.",
        },
      );

      // 6) Generate the Excel file and trigger download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "RecordsTemplate.xlsx");
    },
    async importRecordsTemplate(file) {
      console.log("Importing records template...");
      this.modalIsActive = true;
      this.modalDescription = "Uploading spreadsheet...";
      try {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(file);
        this.modalDescription = "File uploaded...";

        // 1) -------------------------
        // Process the "Fields" sheet first (if present).
        // This ensures new fields & crops exist in the store
        // before we import record sheets that reference them.
        //  ---------------------------
        const fieldsSheet = workbook.getWorksheet("Fields");
        if (fieldsSheet) {
          const columns = fieldsSheet.getRow(1).values.slice(1); // Header row (ignore first empty element)
          const fieldRows = [];

          fieldsSheet.eachRow((row, rowIndex) => {
            if (rowIndex === 1) return; // Skip header

            const record = {};
            row.values.slice(1).forEach((cell, index) => {
              record[columns[index]] = cell || null;
            });

            // Skip if everything is blank except possibly the LookupKey formula
            const cropType = record["Crop Type"];
            const acres = record["Acres"];
            const farm = record["Farm"];
            const field = record["Field"];
            const cropYear = record["Crop Year"];

            const allRequiredEmpty =
              !cropType && !acres && !farm && !field && !cropYear;

            // If row is effectively empty except for the formula, skip
            if (allRequiredEmpty) {
              return;
            }

            // Otherwise, proceed with your normal logic
            fieldRows.push(record);
          });

          // Determine the highest Crop Year across all rows in the Fields sheet
          // (In case some rows are missing a valid year, we'll filter them out)
          const allYears = fieldRows
            .map((r) => parseInt(r["Crop Year"], 10))
            .filter((val) => !isNaN(val));
          this.maxFieldYear =
            allYears.length > 0 ? Math.max(...allYears) : null;

          // For each row in the Fields sheet, ensure we have
          // a valid field and a crop in our store.
          for (const row of fieldRows) {
            // Check each required column
            const cropType = row["Crop Type"];
            const acres = row["Acres"];
            const farm = row["Farm"];
            const field = row["Field"];
            const cropYear = row["Crop Year"];

            // If any of these are blank or invalid, throw error toast
            if (!cropType || !acres || !farm || !field || !cropYear) {
              console.log("Fields sheet is missing data:", row);
              throw new Error(
                `Fields sheet is missing data. Crop Type, Acres, Farm, Field, and Crop Year must be complete in each row.`,
              );
            }

            // Otherwise, proceed with resolveFieldAndCrop, etc.
            await this.resolveFieldAndCrop(row);
          }
        }
        this.modalDescription = "Fields processed. Beginning record import...";

        // 2) -------------------------
        // Now process all other sheets (records).
        //  ---------------------------
        for (const sheet of workbook.worksheets) {
          const sheetName = sheet.name;
          if (
            sheetName === "Fields" ||
            sheetName === "Instructions" ||
            sheetName === "validations"
          ) {
            continue; // Skip the "Fields," "Instructions," and hidden "validations" sheets
          }

          const columns = sheet.getRow(1).values.slice(1); // e.g. ["Crop ID", "Crop Type", ...]

          // 2) Parse records
          const records = [];

          sheet.eachRow((row, rowIndex) => {
            if (rowIndex === 1) return; // Skip header row

            const record = {};

            // Now read each cell in the row after the first cell
            row.values.slice(1).forEach((cell, index) => {
              // If cell is an object (likely a formula), use .result
              let actualValue = "";
              if (cell && typeof cell === "object") {
                if (cell.formula) {
                  // Use the stored result
                  actualValue = cell.result || null;
                } else if (cell.richText) {
                  actualValue =
                    cell.richText.map((rt) => rt.text).join("") || null;
                } else {
                  // Maybe a .text or .value
                  actualValue = cell.text || cell.value || null;
                }
              } else {
                // Normal primitive
                actualValue = cell || null;
              }

              // columns[index] is your column name, e.g. "Crop ID"
              record[columns[index]] = actualValue;
            });

            records.push(record);
          });

          // Special handling for Fertilizer Application and Input Application
          if (
            sheetName === "Fertilizer Application" ||
            sheetName === "Input Application"
          ) {
            const groupedRecords = records.reduce((groups, record) => {
              if (!record["Application Date"]) {
                console.log(
                  `Skipping row due to missing Application Date:`,
                  record,
                );
                return groups;
              }

              // Attempt to match or find a Crop ID if missing
              let cropId = record["Crop ID"];
              if (!cropId) {
                // Use getFieldByName here so aliases are respected
                const matchedField = this.$store.getters.getFieldByName(
                  record["Field"],
                  record["Farm"],
                );
                if (!matchedField) {
                  console.log(
                    `Skipping row due to missing Crop ID and no matching field found ` +
                      `(Field: ${record["Field"]}, Farm: ${record["Farm"]})`,
                  );
                  return groups;
                }

                // Now find the crop referencing matchedField.id
                const matchedCrop = this.crops.find(
                  (c) =>
                    c.field_data?.id === matchedField.id &&
                    c.harvest_year == record["Crop Year"],
                );
                if (!matchedCrop) {
                  console.log(
                    `Skipping row due to missing Crop ID and no matching crop found (Field: ${record["Field"]}, Farm: ${record["Farm"]}, Crop Year: ${record["Crop Year"]})`,
                  );
                  return groups;
                } else {
                  cropId = matchedCrop.id;
                }
              }

              const key = `${cropId}-${record["Application Date"]}`;
              if (!groups[key]) groups[key] = [];
              groups[key].push(record);
              return groups;
            }, {});
            this.modalDescription = "Saving records...";
            // Create or update records
            for (const [key, groupedRows] of Object.entries(groupedRecords)) {
              const [cropId, applicationDate] = key.split("-");
              const crop = this.crops.find((c) => c.id === cropId);
              if (!crop) {
                console.log(
                  `Could not find crop with id ${cropId}, skipping...`,
                );
                continue;
              }

              // Build the payload for mix_products
              const mixProducts = groupedRows.map((row) => {
                const mixProduct = {};
                const mixProductAttributes =
                  this.getMixProductAttributes(sheetName);
                mixProductAttributes.forEach((attr) => {
                  mixProduct[attr.value] = row[attr.label] || "";
                });
                return mixProduct;
              });

              const existingRecord = groupedRows.find(
                (row) => row["Record ID"],
              );
              const recordId = existingRecord
                ? existingRecord["Record ID"]
                : null;

              const payload = {
                application_date: applicationDate,
                mix_products: mixProducts,
              };

              // Add the other payload attributes
              const recordTypeDefinition = RecordTypeDefinitions.find(
                (type) => type.category === sheetName,
              );
              if (recordTypeDefinition) {
                recordTypeDefinition.data.payload
                  .filter((attr) => attr.value !== "mix_products")
                  .forEach((attr) => {
                    const attrValue = groupedRows[0][attr.label];
                    if (attrValue !== undefined)
                      payload[attr.value] = attrValue;
                  });
              }

              const recordData = {
                category: sheetName,
                crop: crop.id,
                result: null,
                payload,
                completed_at: null,
              };
              console.log("computing result:", recordData);
              recordData.result = recordResult(
                recordTypeDefinition,
                recordData,
              );

              if (recordId) {
                // Update existing record
                console.log(`Updating record ${recordId} for crop ${cropId}`);
                await this.$store.dispatch("updateRecord", {
                  recordId,
                  record: recordData,
                });
              } else {
                // Create a new record
                console.log(`Creating new record for crop ${cropId}`);
                await this.$store.dispatch("createRecord", recordData);
              }
            }
          } else {
            // Generic handling for other record types
            for (const record of records) {
              const payload = {};
              const recordTypeDefinition = RecordTypeDefinitions.find(
                (type) => type.category === sheetName,
              );

              if (recordTypeDefinition) {
                recordTypeDefinition.data.payload.forEach((attr) => {
                  if (record[attr.label] !== undefined) {
                    payload[attr.value] = record[attr.label];
                  }
                });
              }

              // If there is no record data and no Record ID, skip
              const hasPayloadData = Object.values(payload).some(
                (value) =>
                  value !== "" && value !== undefined && value !== null,
              );
              if (!record["Record ID"] && !hasPayloadData) {
                console.log(
                  `Skipping record for crop ${record["Crop ID"]}: Empty payload and no Record ID.`,
                );
                continue;
              }

              let cropId = record["Crop ID"];
              if (!cropId) {
                // Use getFieldByName so we can respect field aliases
                const matchedField = this.$store.getters.getFieldByName(
                  record["Field"],
                  record["Farm"],
                );
                if (!matchedField) {
                  console.log(
                    `Skipping row: no matching field found ` +
                      `(Field: ${record["Field"]}, Farm: ${record["Farm"]})`,
                  );
                  continue;
                }

                const matchedCrop = this.crops.find(
                  (c) =>
                    c.field_data?.id === matchedField.id &&
                    c.harvest_year == record["Crop Year"],
                );
                if (!matchedCrop) {
                  console.log(
                    `Skipping row due to missing Crop ID and no matching crop found (Field: ${record["Field"]}, Farm: ${record["Farm"]}, Crop Year: ${record["Crop Year"]})`,
                  );
                  continue;
                } else {
                  cropId = matchedCrop.id;
                }
              }

              const crop = this.crops.find((c) => c.id === cropId);
              if (!crop) {
                console.log(
                  `Could not find crop with id ${cropId}, skipping...`,
                );
                continue;
              }

              const recordData = {
                category: sheetName,
                crop: crop.id,
                result: null,
                payload,
                completed_at: null,
              };
              console.log("computing result:", recordData);
              recordData.result = recordResult(
                recordTypeDefinition,
                recordData,
              );
              if (record["Record ID"]) {
                // Update existing record
                console.log(
                  `Updating record ${record["Record ID"]} for crop ${crop.id}`,
                );
                await this.$store.dispatch("updateRecord", {
                  recordId: record["Record ID"],
                  record: recordData,
                });
              } else {
                // Create a new record
                console.log(`Creating new record for crop ${crop.id}`);
                await this.$store.dispatch("createRecord", recordData);
              }
            }
          }
        }
        this.modalIsActive = false;
        this.toast.success("Import successful.");
        this.$store.dispatch("getFields");
        this.$store.dispatch("getCrops");
        this.$store.dispatch("getRecords");
        this.$store.dispatch("getGrowerCropOrders");
        this.$store.dispatch("getGrowerAllocations");
      } catch (error) {
        this.modalIsActive = false;
        this.toast.error(error.message);
        return;
      }
    },

    /**
     * Helper function: ensure a given row from the Fields sheet has a valid field & crop.
     *  1. If "Crop ID" is present, do nothing (we assume it’s already known).
     *  2. If missing "Crop ID", attempt to find or create a matching field:
     *     - Match field + farm
     *     - Match field alone if farm match not found
     *     - Create a new field if still not found
     *  3. Create a new crop against the found/created field, setting `harvest_range_end` to
     *     the last day of the `Crop Year` (e.g. YYYY-12-31).
     *  4. Only allocate the crop to this.orderId if the row's crop year
     *     matches the highest crop year found in the Fields sheet (this.maxFieldYear).
     */
    async resolveFieldAndCrop(row) {
      // Initialize local caches if they do not exist.
      if (!this.importedFields) {
        this.importedFields = {}; // keys will be "farmName::fieldName"
      }
      if (!this.importedCrops) {
        this.importedCrops = {}; // keys will be "fieldId::cropYear"
      }

      // If a Crop ID is already provided, we assume this row is already linked.
      const cropId = row["Crop ID"];
      if (cropId) {
        return;
      }

      const fieldName = row["Field"];
      const farmName = row["Farm"];
      const cropYear = row["Crop Year"];

      if (!fieldName) {
        console.log("Skipping row due to missing Field name:", row);
        return;
      }

      // Build a unique key for the field based on its name and farm.
      const fieldKey = `${farmName}::${fieldName}`;

      // Check if we already have this field in our local cache.
      let matchedField = this.importedFields[fieldKey];

      // If not in our cache, try to get it from the store.
      if (!matchedField) {
        matchedField = this.$store.getters.getFieldByName(fieldName, farmName);
      }

      // If still not found, create the new field and store it in the cache.
      if (!matchedField) {
        console.log(
          `Creating new field: Name="${fieldName}", Farm="${farmName}"`,
        );
        const newFieldData = {
          geometry: null,
          properties: {
            name: fieldName,
            farm: farmName,
            acres: row["Acres"] || 0,
          },
        };

        matchedField = await this.$store.dispatch("createField", newFieldData);
        console.log("Created new field:", matchedField);

        // Cache the newly created field
        this.importedFields[fieldKey] = matchedField;
      }

      // If there is no valid crop year, skip crop creation.
      if (!cropYear) {
        console.log("No crop year provided, skipping crop creation:", row);
        return;
      }

      // Build a unique key for the crop using the field ID and crop year.
      const cropKey = `${matchedField.id}::${cropYear}`;
      let matchedCrop = this.importedCrops[cropKey];

      // If not in our local cache, check among the existing crops.
      if (!matchedCrop) {
        matchedCrop = this.crops.find(
          (c) =>
            c.field_data?.id === matchedField.id && c.harvest_year == cropYear,
        );
      }

      // If no matching crop is found, create one and cache it.
      if (!matchedCrop) {
        // Convert crop year string to a date string for December 31 of that year.
        const year = parseInt(cropYear, 10);
        const harvestRangeEnd = new Date(year, 11, 31)
          .toISOString()
          .slice(0, 10);

        const newCropData = {
          fieldId: matchedField.id,
          crop: {
            active: cropYear === this.maxFieldYear,
            crop_type: row["Crop Type"] || null,
            harvest_range_end: harvestRangeEnd,
            details: {},
          },
        };
        console.log("Creating new crop:", newCropData);
        matchedCrop = await this.$store.dispatch("createCrop", newCropData);
        console.log("Created new crop:", matchedCrop);

        // Cache the crop so that later rows for the same field & year reuse it.
        this.importedCrops[cropKey] = matchedCrop;
      }

      // Automatically allocate acres to the current order if applicable.
      if (this.orderId && this.maxFieldYear && cropYear === this.maxFieldYear) {
        const allocationToSubmit = {
          crop_order: Number(this.orderId),
          crop: matchedCrop.id,
          acres: row["Acres"] || 0,
        };
        await this.$store.dispatch(
          "createGrowerAllocation",
          allocationToSubmit,
        );
        // if (!this.$store.getters.getApiError) {
        //   this.toast.success("Allocation Saved to Order!", {
        //     timeout: 750,
        //     hideProgressBar: true,
        //   });
        // }
      }
    },
    handleFileInput(event) {
      console.log(event);
      // const file = event.target.files[0];
      const file = event; // using file-select component
      if (file) {
        this.importRecordsTemplate(file);
      }
    },
  },
  computed: {
    orderId() {
      return this.$route.params.orderId;
    },
    cropOptions() {
      let options = [];
      for (let crop of cropList) {
        options.push(crop.name);
      }
      return options;
    },
    dataIsLoaded() {
      return this.premiums.length > 0;
    },
    validation_records() {
      if (!this.dataIsLoaded) {
        return [];
      }
      let records = [];
      for (let premium of this.premiums) {
        for (let record of premium.validation_records) {
          records.push({ ...record, premium_id: premium.id });
        }
      }
      return records;
    },
    cropsForExport() {
      if (!this.dataIsLoaded) {
        return [];
      }
      return this.crops.map((crop) => {
        return {
          crop_id: crop.id,
          farm: crop?.field_data?.properties?.farm,
          field: crop?.field_data?.properties?.name,
          acres: crop?.field_data?.properties?.acres,
          crop_type: crop.crop_type,
          crop_year: crop.harvest_year,
          records: crop.records,
          // relatedAllocationId: crop.relatedAllocationId,
        };
      });
    },
    fields() {
      return this.$store.getters.getAllFields;
    },
  },
  watch: {
    // watch
  },
};
</script>

<template>
  <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
    <div class="border-b border-gray-200">
      <TabList class="-mb-px flex space-x-8">
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Field Allocations
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Allocations Chart
          </button>
        </Tab>
        <Tab as="template" v-slot="{ selected }">
          <button
            :class="[
              selected
                ? 'border-indigo-600 text-indigo-600'
                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
              'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
            ]"
          >
            Buyer's Map View
          </button>
        </Tab>
      </TabList>
    </div>
    <TabPanels as="template">
      <TabPanel class="pt-10" :unmount="false">
        <grower-portal-allocations-report />
      </TabPanel>
      <TabPanel as="dl" class="pt-10">
        <grower-portal-allocations-chart />
      </TabPanel>
      <TabPanel class="pt-10">
        <buyers-map />
      </TabPanel>
    </TabPanels>
  </TabGroup>
</template>

<script>
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import GrowerPortalAllocationsReport from "@/layouts/grower-portal/sell/GrowerPortalAllocationsReport";
import GrowerPortalAllocationsChart from "@/layouts/grower-portal/sell/GrowerPortalAllocationsChart";
import BuyersMap from "@/layouts/grower-portal/sell/BuyersMap.vue";
export default {
  name: "ProduceReports",
  components: {
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    GrowerPortalAllocationsReport,
    GrowerPortalAllocationsChart,
    BuyersMap,
  },

  data() {
    // console.log(this.$route.query);
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return { selectedTab: selectedTab };
  },
  computed: {},
  methods: {
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      this.$router.push({
        query: { tab: index },
      });
    },
  },
};
</script>

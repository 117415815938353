<template>
  <div
    class="my-2 rounded-sm bg-gray-50 pl-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Records Entered Per Field
  </div>
  <div class="sm:col-span-6">
    <table-card v-if="allocations && showFieldSelect">
      <template #header>
        <table-head
          :columns="columns"
          :collection="tableRows"
          :defaultSort="defaultSort"
          @update:derived-collection="derivedRows = $event"
          :toolbar="2"
        >
        </table-head>
      </template>

      <tbody>
        <tr v-for="row in derivedRows" :key="row">
          <table-data td-label="Field">
            <!-- <span v-if="idx == 0">{{ allo.field_name }}</span> -->
            {{ row.field_name }}
          </table-data>
          <table-data td-label="Category">
            {{ row.category }}
          </table-data>
          <template v-for="year in row.years" :key="year">
            <table-data td-label="year.name">
              <span
                :class="
                  year.count == 0 ? 'bg-red-200 px-4' : 'bg-green-200 px-4'
                "
              >
                {{ year.count }}</span
              >
            </table-data>
          </template>
        </tr>
      </tbody>
    </table-card>
  </div>
</template>

<script>
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";

import { useToast } from "vue-toastification";

import { interpretPremium } from "@/components/composables/growerPremiumUtils.js";

export default {
  emits: ["selectRecords"],
  components: {
    // ToggleButton,
    TableCard,
    TableHead,
    TableData,
  },
  data() {
    return {
      toast: useToast(),
      defaultSort: { field_name: "asc" },
      derivedRows: [],
      showFieldSelect: true,
    };
  },
  computed: {
    // CORE DATA
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    currentOrder() {
      // use this.orderId to fetch the order
      return this.$store.getters.getBuyerOrderById(this.orderId);
    },
    currentPremiumId() {
      if (!this.currentOrder) {
        return null;
      }
      return this.currentOrder.premium;
    },
    currentOffer() {
      // use this.orderId to fetch the offer
      return this.$store.getters.getBuyerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    // allocatedRecords() {
    //   let records = [];
    //   // use this.orderId to fetch the allocated records
    //   for (let allocation of this.allocations) {
    //     for (let record of allocation.shared_data.record_details) {
    //       records.push({ ...record, crop: allocation.crop });
    //     }
    //   }
    //   return records;
    // },

    validationCategories() {
      if (!this.currentOffer) {
        return [];
      }
      let categories = [];
      for (let prem of this.currentOffer.premiums) {
        for (let record of prem.validation_records) {
          if (!categories.includes(record.category)) {
            categories.push(record.category);
          }
        }
      }
      return categories;
    },
    contractYears() {
      let offer = this.currentOffer;
      if (!offer) {
        return [];
      }
      let current_year = offer.program_year;
      let prior_years = offer.additional_prior_year_records;
      let first_year = current_year - prior_years;

      let years = [];
      for (let i = 0; i <= prior_years; i++) {
        years.push(first_year + i);
      }
      return years;
    },
    allAffiliateRecords() {
      return this.$store.getters.getAllBuyerAllocationRecords;
    },

    allocations() {
      // use this.orderId to fetch the allocations
      // for each allocation, attach the crop_data and field_data
      let allocations = structuredClone(
        this.$store.getters.getBuyerAllocationsByOrder(this.orderId),
      );
      let cropRecords = [];
      for (let crop of this.allAffiliateRecords) {
        let recordCount = 0;
        if (crop.harvest_range_end) {
          crop.crop_year = new Date(crop.harvest_range_end).getFullYear();
        } else {
          crop.crop_year = new Date().getFullYear();
        }
        for (let record of crop.record_details) {
          if (record.category == "NDVI") continue;
          recordCount++;
        }
        if (recordCount > 0) cropRecords.push(crop);
      }
      for (let allocation of allocations) {
        // put in records, to distinguish from the old records in shared_data.
        allocation.crops = cropRecords.filter(
          (record) => record.allocation === allocation.id,
        );
        allocation.crops.sort((a, b) => a.crop_year - b.crop_year);
      }
      let enhancedAllocations = allocations.map((allo) => {
        return {
          ...allo,

          field_data: allo.shared_data?.field_data,
          // currentOrderValidations equals allo.validations_met filtered down to validation ids that are in the currentOffer.premiums
          currentOrderValidations: allo.validations_met.filter((val) =>
            this.currentOffer.premiums.map((prem) => prem.id).includes(val),
          ),
          validatedAgainstSelectedPremium: allo.validations_met.includes(
            this.currentPremiumId,
          ),
        };
      });
      for (let allo of enhancedAllocations) {
        allo.field_name = allo?.field_data?.properties?.name;
      }
      return enhancedAllocations;
    },

    columns() {
      let columns = [];
      columns.push({
        name: "field_name",
        label: "Field",
        filtered: true,
        sorted: true,
      });
      columns.push({
        name: "category",
        label: "Category",
        filtered: true,
        sorted: true,
      });
      for (let year of this.contractYears) {
        columns.push({
          name: year.toString(),
          label: year.toString(),
          filtered: false,
          sorted: false,
        });
      }
      return columns;
    },
    tableRows() {
      if (!this.allocations) {
        return [];
      }
      let rows = [];
      let allocations = structuredClone(this.allocations);
      for (let allo of allocations) {
        let newField = true;
        for (let category of this.validationCategories) {
          let row = {};
          if (newField)
            row.field_name = allo?.shared_data.field_details?.properties?.name;
          else row.field_name = "";
          row.category = category;
          row.years = [];
          for (let year of this.contractYears) {
            let crops = allo.crops.filter(
              (crop) =>
                crop.crop_year == year && crop.record_details.length > 0,
            );

            let count = 0;
            for (let crop of crops) {
              for (let record of crop.record_details) {
                if (record.category == category) {
                  count++;
                }
              }
            }
            row.years.push({
              name: year,
              count: count,
            });
          }
          rows.push(row);
        }
      }
      return rows;
    },
  },
  methods: {
    interpretPremium,
  },
  watch: {},
};
</script>
